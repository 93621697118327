import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateRulePreview = ({ rule }) => {
  if (!rule) {
    // Return null or a fallback UI if rule is not defined
    return null;
  }

  const colors = ['text-red-700', 'text-blue-700', 'text-green-700', 'text-yellow-700'];

  return (
    <li className={classNames("relative flex flex-col justify-between gap-y-2 py-3 px-1 bg-white border-l-4 border-indigo-500")}>
      <div className="min-w-0 px-2 flex justify-between items-center">
        <div className="flex items-center gap-x-5">
          <p className={classNames("text-xsm leading-6 text-gray-900 font-semibold text-indigo-600")}>
            {rule.ruleName}
          </p>
          <p
            className={classNames(
              'text-gray bg-[#C2B9F6] ring-1 ring-[#8A79EC]',
              'whitespace-nowrap rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset'
            )}
          >
            {rule.lastRunIssueCount} Issues
          </p>
        </div>
      </div>
      <div className="min-w-0 px-2 flex flex-wrap gap-y-1 my-1">
        {rule.soqlConditions && rule.soqlConditions.map((condition, index) => (
          <React.Fragment key={index}>
            <div className={`inline-block text-xxs ${colors[index % colors.length]} bg-gray-100 p-1 rounded border font-mono`}>
              <span>{condition}</span>
            </div>
            {index < rule.soqlConditions.length - 1 && (
              <div className="inline-block text-xxs text-gray-500 font-mono m-1">
                AND
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="min-w-0 px-2 flex justify-between items-center">
        <p className="text-xs leading-5 text-gray-500">
          Last run: Never
        </p>
      </div>
    </li>
  );
};

export default CreateRulePreview;