// ErrorBoundary.jsx
import React from 'react';
import ErrorDialog from './ErrorDialog.jsx';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    if (this.props.onError) {
      this.props.onError(error, errorInfo); // Optionally send error details to server
    }
  }

  closeErrorDialog = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  render() {
    const { hasError, error, errorInfo } = this.state;

    return (
      <>
        <ErrorDialog
          isOpen={hasError}
          error={error}
          errorInfo={errorInfo}
          onClose={this.closeErrorDialog}
        />
        {/* Render the children components even when an error occurs */}
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundary;