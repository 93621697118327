import React, { useState } from 'react';

const AttachScreenshot = ({ onScreenshotChange }) => {
    const [screenshots, setScreenshots] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        validateAndSetFiles(files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        validateAndSetFiles(files);
    };

    const validateAndSetFiles = (files) => {
        // Check if adding these files would exceed the 5 screenshot limit
        if (screenshots.length + files.length > 5) {
            setErrorMessage('You can only upload 5 screenshots at a time. But thanks for your enthusiasm!! ');
            return;
        }

        const validFiles = [];
        const validPreviews = [];

        files.forEach((file) => {
            if (!file.type.startsWith('image/')) {
                setErrorMessage('Please upload valid image files.');
                return;
            }

            const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
            if (file.size > maxSizeInBytes) {
                setErrorMessage('One or more files exceed the 5MB limit.');
                return;
            }

            // If valid, add to the arrays
            validFiles.push(file);
            validPreviews.push(URL.createObjectURL(file)); // Create preview URLs
        });

        // Update state only if there are valid files
        if (validFiles.length > 0) {
            setScreenshots([...screenshots, ...validFiles]);
            setPreviewUrls([...previewUrls, ...validPreviews]);
            setErrorMessage('');
            onScreenshotChange([...screenshots, ...validFiles]); // Notify parent component
        }
    };

    return (
        <div>
            {/* Drag and Drop Area */}
            <div
                className="mt-4 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-1 pb-6"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
            >
                <div className="space-y-1 text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            d="M14 32l10-10 10 10M24 22v16"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="flex justify-center items-center text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none hover:text-indigo-500"
                        >
                            <span>Upload a file</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleFileSelect}
                            />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG up to 5MB each, max 5 screenshots</p>
                    {errorMessage && (
                        <p className="text-sm text-red-600 mt-2">{errorMessage}</p>
                    )}
                </div>
            </div>

            {/* Preview Section */}
            {previewUrls.length > 0 && (
                <div className="mt-4">
                    <p className="text-sm text-gray-600">Screenshot Previews:</p>
                    <div className="mt-2 flex flex-wrap gap-2">
                        {previewUrls.map((url, index) => (
                            <img
                                key={index}
                                src={url}
                                alt={`Screenshot Preview ${index + 1}`}
                                className="w-24 h-24 rounded-md border border-gray-300 object-cover"
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AttachScreenshot;