import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setIsOutOfCreditsOpen, setIsBuyCreditsOpen } from '../slices/uiSlice.js'; 

export default function OutOfCredits({ isOpen, setIsOpen }) { // Accept isOpen and setIsOpen as props
  const dispatch = useDispatch();

  const handleAddCredits = () => {
    dispatch(setIsOutOfCreditsOpen(false)); // Close OutOfCredits modal
    dispatch(setIsBuyCreditsOpen(true)); // Open BuyCredits modal
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex items-center justify-center gap-2 sm:flex sm:items-center sm:justify-start">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
              </div>
              <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900 text-center sm:text-left">
                Out of Credits
              </DialogTitle>
            </div>
            <div className="mt-4 text-center sm:text-left">
              <p className="text-gray-600 mb-8 ml-6">
                You ran out of credits! Your scheduled runs have been paused. Add more credits to continue using tarnData.
              </p>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleAddCredits} // Open BuyCredits modal and close OutOfCredits modal
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Add Credits
              </button>
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}