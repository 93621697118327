// Helper function to convert issues to CSV format
const convertIssuesToCSV = (filteredIssues) => {
    if (!filteredIssues.length) {
      return ''; // Return empty string if there are no issues
    }
  
    // Get column headers from the first issue's previewFields
    const previewFieldHeaders = filteredIssues[0].previewFields.map((field) => field.name);
    let headers = ['Salesforce ID', 'Status', ...previewFieldHeaders];
  
    // Create CSV rows
    const rows = filteredIssues.map((issue) => {
      // Base row values for each issue: salesforceId, status, previewFields
      const previewFieldValues = issue.previewFields.map((field) => field.value);
      let row = [issue.salesforceId, issue.status, ...previewFieldValues];
  
      // Track existing actions by actionKey to modify later with actionTaken data
      const actionMap = {};
  
      // Case 1: No issue.fix
      if (!issue.fix) {
        // No fix exists, only preview fields will be added, which is already handled
      }
  
      // Case 2: issue.fix exists, but actionTaken is empty
      else if (issue.fix && (!issue.actionTaken || issue.actionTaken.length === 0)) {
        // Find the first condition where evaluation = true
        const evaluatedCondition = issue.fix.conditions.find(condition => condition.evaluation === true);
  
        if (evaluatedCondition) {
          // Loop through the actions array in the evaluated condition
          evaluatedCondition.actions.forEach((action, index) => {
            // Add headers if needed (dynamically extend for actions)
            if (!headers.includes(`actionKey_${index + 1}`)) {
              headers.push(`actionKey_${index + 1}`, `fieldName_${index + 1}`, `newValue_${index + 1}`, `oldValue_${index + 1}`, `success_${index + 1}`);
            }
  
            // Add the action values to the row and keep track of the action for future updates
            row.push(action.actionKey || '', action.fieldName || '', action.newValue || '', '', ''); // 'oldValue' and 'success' are placeholders for now
            actionMap[action.actionKey] = { index: index + 1 }; // Track the action index for future reference
          });
        }
      }
  
      // Case 3: issue.fix exists and actionTaken is not empty
      else if (issue.fix && issue.actionTaken && issue.actionTaken.length > 0) {
        // Loop through the actions in issue.fix
        const evaluatedCondition = issue.fix.conditions.find(condition => condition.evaluation === true);
  
        if (evaluatedCondition) {
          evaluatedCondition.actions.forEach((action, index) => {
            // Add headers for actions if not present
            if (!headers.includes(`actionKey_${index + 1}`)) {
              headers.push(`actionKey_${index + 1}`, `fieldName_${index + 1}`, `newValue_${index + 1}`, `oldValue_${index + 1}`, `success_${index + 1}`);
            }
  
            // Add action values
            row.push(action.actionKey || '', action.fieldName || '', action.newValue || '', '', ''); // Placeholder for oldValue and success
            actionMap[action.actionKey] = { index: index + 1 };
          });
        }
  
        // Now we process actionTaken and match them to the corresponding actions by actionKey
        issue.actionTaken.forEach((action) => {
          if (actionMap[action.actionKey]) {
            const actionIndex = actionMap[action.actionKey].index;
            // Add oldValue and success next to the correct actionKey
            row[headers.indexOf(`oldValue_${actionIndex}`)] = action.oldValue || '';
            row[headers.indexOf(`success_${actionIndex}`)] = action.success ? 'true' : 'false';
          }
        });
      }
  
      return row;
    });
  
    // Combine headers and rows into CSV content
    let csvContent = `${headers.join(',')}\n`; // Join headers with commas
    rows.forEach(row => {
      csvContent += row.join(',') + '\n'; // Join row values with commas
    });
  
    return csvContent;
  };
  
  // Function to trigger CSV download
  const downloadCSV = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url); // Clean up the URL object
  };
  
  // Function to format date in "YYYY-MMM-DD-h.mma" format
  const formatTimestamp = () => {
    const date = new Date();
    
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    let formattedDate = date.toLocaleString('en-US', options);
    
    // Remove all commas and replace characters for the desired format
    formattedDate = formattedDate.replace(/,/g, '').replace(/\s/g, '-').replace(':', '.');
  
    return formattedDate;
  };
  
  // Main function to download issues as a CSV file
  const downloadIssues = (filteredIssues, ruleName) => {
    if (!filteredIssues || filteredIssues.length === 0) {
      console.error('No issues to download');
      return;
    }
  
    // Get the formatted timestamp
    const timestamp = formatTimestamp();
  
    // Create a filename using the ruleName and formatted timestamp
    const fileName = `${ruleName}_issues_${timestamp}.csv`;
  
    const csvContent = convertIssuesToCSV(filteredIssues);
    downloadCSV(csvContent, fileName); // Pass the dynamically created filename
  
    console.log(`Downloading issues for this many issues: ${filteredIssues.length}`);
  };
  
  export default downloadIssues;