import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const RunDetails = ({ runId, details, onClose }) => {
  const [issueActions, setIssueActions] = useState(details ? details.slice(0, 25) : []);
  const [totalActions, setTotalActions] = useState(details ? details.length : 0);
  const [loading, setLoading] = useState(!details);

  useEffect(() => {
    if (!details) {
      const fetchRunDetails = async () => {
        try {
          const response = await axiosInstance.get(`/api/get-run-details/${runId}`);
          setIssueActions(response.data.slice(0, 25));
          setTotalActions(response.data.length);
        } catch (error) {
          console.error('Error fetching run details:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchRunDetails();
    }
  }, [runId, details]);

  if (loading) {
    return <div className="text-gray-500">Loading run details...</div>;
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm text-gray-700 font-semibold">
          Showing {totalActions > 25 ? '25' : totalActions} of {totalActions} updates
        </span>
        <button className="text-indigo-600 font-bold hover:underline" onClick={onClose}>
          Collapse
        </button>
      </div>

      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
              Salesforce ID
            </th>
            <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
              Field Name
            </th>
            <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
              Old Value
            </th>
            <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
              New Value
            </th>
            <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6">
              <span className="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {issueActions.map((action, idx) => (
            <tr key={idx}>
              <td className={classNames(
                  idx !== issueActions.length - 1 ? 'border-b border-gray-200' : '',
                  'whitespace-nowrap px-3 py-4 text-xs text-gray-900'
                )}
              >
                {action.salesforceId}
              </td>
              <td className={classNames(
                  idx !== issueActions.length - 1 ? 'border-b border-gray-200' : '',
                  'whitespace-nowrap px-3 py-4 text-xs text-gray-500'
                )}
              >
                {action.fieldName}
              </td>
              <td className={classNames(
                  idx !== issueActions.length - 1 ? 'border-b border-gray-200' : '',
                  'px-3 py-4 text-xs text-gray-500 break-words'
                )}
              >
                {action.oldValue}
              </td>
              <td className={classNames(
                  idx !== issueActions.length - 1 ? 'border-b border-gray-200' : '',
                  'px-3 py-4 text-xs text-gray-500 break-words'
                )}
              >
                {action.newValue}
              </td>
              <td className={classNames(
                  idx !== issueActions.length - 1 ? 'border-b border-gray-200' : '',
                  'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6'
                )}
              >
                <a href={action.salesforceLink} className="text-indigo-600 hover:underline">
                  View Record
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RunDetails;