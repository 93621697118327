import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isCreateRuleOpen: false,
    isCreateFixOpen: false,
    isGeneralFeedbackOpen: false,
    isLiveActive: false,
    isBuyCreditsOpen: false,
    isCreateScheduleOpen: false,
    isScheduleActive: false,
    isAuditActive: false,
    isSettingsActive: false,
    isPreventRunOpen: false, // State for PreventRun overlay
    preventRunMessage: null, // Stores backend message for PreventRun
    preventRunSeconds: 0, // New state for countdown seconds
    isOutOfCreditsOpen: false,
    isOldDataOpen: false,
    isProvisioning: false,
    isAddSalesforceObjectOpen: false,
    isGetContactEmailOpen: false,
    isDeleteRuleOpen: false,
    deleteRuleId: null,
  },
  reducers: {
    setIsCreateRuleOpen: (state, action) => {
      state.isCreateRuleOpen = action.payload;
    },
    setIsCreateFixOpen: (state, action) => {
      state.isCreateFixOpen = action.payload;
    },
    setIsGeneralFeedbackOpen: (state, action) => {
      state.isGeneralFeedbackOpen = action.payload;
    },
    setIsLiveActive: (state, action) => {
      state.isLiveActive = action.payload;
    },
    setIsBuyCreditsOpen: (state, action) => {
      state.isBuyCreditsOpen = action.payload;
    },
    setIsCreateScheduleOpen: (state, action) => {
      state.isCreateScheduleOpen = action.payload;
    },
    setIsScheduleActive: (state, action) => {
      state.isScheduleActive = action.payload;
    },
    setIsAuditActive: (state, action) => {
      state.isAuditActive = action.payload;
    },
    setIsSettingsActive: (state, action) => {
      state.isSettingsActive = action.payload;
    },
    setIsPreventRunOpen: (state, action) => {
      state.isPreventRunOpen = action.payload;
    },
    setPreventRunMessage: (state, action) => {
      state.preventRunMessage = action.payload;
    },
    setPreventRunSeconds: (state, action) => { // New action for countdown seconds
      state.preventRunSeconds = action.payload;
    },
    setIsOutOfCreditsOpen: (state, action) => {
      state.isOutOfCreditsOpen = action.payload;
    },
    setIsOldDataOpen: (state, action) => {
      state.isOldDataOpen = action.payload;
    },
    setIsProvisioning: (state, action) => {
      state.isProvisioning = action.payload;
    },
    setIsAddSalesforceObjectOpen: (state, action) => {
      state.isAddSalesforceObjectOpen = action.payload;
    },
    setIsGetContactEmailOpen: (state, action) => {
      state.isGetContactEmailOpen = action.payload;
    },
    setIsDeleteRuleOpen: (state, action) => {
      state.isDeleteRuleOpen = action.payload;
    },
    setDeleteRuleId: (state, action) => {
      state.deleteRuleId = action.payload;
    },
  },
});

// Export actions
export const {
  setIsCreateRuleOpen,
  setIsCreateFixOpen,
  setIsGeneralFeedbackOpen,
  setIsLiveActive,
  setIsBuyCreditsOpen,
  setIsCreateScheduleOpen,
  setIsScheduleActive,
  setIsAuditActive,
  setIsSettingsActive,
  setIsPreventRunOpen, // Action for PreventRun overlay
  setPreventRunMessage, // Action for PreventRun message
  setPreventRunSeconds, // New action for countdown seconds
  setIsOutOfCreditsOpen,
  setIsOldDataOpen,
  setIsProvisioning,
  setIsAddSalesforceObjectOpen,
  setIsGetContactEmailOpen,
  setIsDeleteRuleOpen,
  setDeleteRuleId,
} = uiSlice.actions;

export default uiSlice.reducer;