import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAddSalesforceObjectOpen } from '../../slices/uiSlice.js';
import { fetchSalesforceObjects } from '../../slices/salesforceObjectsSlice.js';
import axiosInstance from '../../axiosInstance.js';
import CurrentConfig from './add-object/CurrentConfig.jsx';
import SearchObjects from './add-object/SearchObjects.jsx';
import PickHeroIcon from './add-object/PickHeroIcon.jsx';

const AddSalesforceObject = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.ui.isAddSalesforceObjectOpen);
    const existingObjects = useSelector((state) => state.salesforceObjects.data);

    const [salesforceObjects, setSalesforceObjects] = useState([]);
    const [selectedObjects, setSelectedObjects] = useState([]);
    const [selectedObject, setSelectedObject] = useState(null); // Track the currently selected object
    const [showPickHeroIcon, setShowPickHeroIcon] = useState(false); // Track visibility of PickHeroIcon component
    const [isSaving, setIsSaving] = useState(false); // Track whether the save process is ongoing

    useEffect(() => {
        if (existingObjects.length > 0) {
            setSelectedObjects(existingObjects);
        }
    }, [existingObjects]);

    useEffect(() => {
        if (isOpen) {
            const fetchSalesforceObjectOptions = async () => {
                try {
                    const response = await axiosInstance.get('/api/get-salesforce-object-options');
                    setSalesforceObjects(response.data);
                } catch (error) {
                    console.error('Failed to fetch Salesforce object options:', error);
                }
            };

            fetchSalesforceObjectOptions();

            if (existingObjects.length === 0) {
                dispatch(fetchSalesforceObjects());
            }
        }
    }, [isOpen, dispatch, existingObjects.length]);

    // Handle saving the configuration
    const handleSave = async () => {
        setIsSaving(true); // Start the loading state when save is initiated
        try {
            await axiosInstance.post('/api/edit-salesforce-config', {
                salesforceObjects: selectedObjects,
            });
            console.log('Salesforce config updated successfully.');
            // Reset the state and close the modal after saving
            setSelectedObject(null);
            setShowPickHeroIcon(false);
            dispatch(fetchSalesforceObjects());

            dispatch(setIsAddSalesforceObjectOpen(false)); // Close the modal after saving
        } catch (error) {
            console.error('Failed to save Salesforce config:', error);
        } finally {
            setIsSaving(false); // End the loading state when save is complete
        }
    };

    const handleAddObject = (obj) => {
        const isExistingObject = selectedObjects.some((item) => item.name === obj.name);
        if (!isExistingObject) {
            setSelectedObjects((prev) => [...prev, { ...obj, newlyAdded: true }]);
            setSelectedObject(obj);       // Set the selected object
            setShowPickHeroIcon(true);    // Show the PickHeroIcon component
        }
    };

    const handleRemoveObject = (objToRemove) => {
        setSelectedObjects((prev) => prev.filter((obj) => obj.name !== objToRemove.name));
        if (selectedObject?.name === objToRemove.name) setSelectedObject(null); // Reset selected object if removed
    };

    const handleSelectObject = (obj) => {
        setSelectedObject(obj);
        setShowPickHeroIcon(true); // Show the PickHeroIcon component when an object is selected
    };

    const handleSelectIcon = (iconName) => {
        setSelectedObjects((prev) =>
            prev.map((obj) => (obj.name === selectedObject.name ? { ...obj, heroIcon: iconName } : obj))
        );
    };

    // Handle cancel action and reset the selectedObject state
    const handleCancel = () => {
        setSelectedObject(null); // Reset selectedObject state when the dialog is closed
        setShowPickHeroIcon(false); // Reset the visibility of the PickHeroIcon
        dispatch(setIsAddSalesforceObjectOpen(false)); // Close the modal
    };

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog open={isOpen} onClose={handleCancel} className="relative z-10">
                {/* Background overlay */}
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* Centered modal */}
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {/* Close button */}
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={handleCancel}
                                    >
                                        <span className="sr-only">Cancel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                {/* Modal content */}
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Add Salesforce Objects
                                        </Dialog.Title>

                                        {/* Include the SearchObjects component and pass necessary props */}
                                        <SearchObjects salesforceObjects={salesforceObjects} onAddObject={handleAddObject} />
                                    </div>

                                    {/* Include the CurrentConfig component and pass necessary props */}
                                    <CurrentConfig
                                        selectedObjects={selectedObjects}
                                        onRemoveObject={handleRemoveObject}
                                        onSelectObject={handleSelectObject}
                                        selectedObject={selectedObject}
                                    />

                                    {/* Loading animation during saving */}
                                    {isSaving && (
                                        <div className="flex justify-center items-center mt-4">
                                            <l-line-wobble
                                              size="300"
                                              stroke="5"
                                              bg-opacity="0.1"
                                              speed="1.75"
                                              color="#8A79EC"
                                            ></l-line-wobble>
                                        </div>
                                    )}

                                    {/* Render PickHeroIcon below CurrentConfig when an object is selected */}
                                    {showPickHeroIcon && (
                                        <PickHeroIcon
                                            onSelectIcon={handleSelectIcon}
                                            onClose={() => setShowPickHeroIcon(false)}
                                            object={selectedObject} // Pass the selected object to show its label
                                        />
                                    )}
                                </div>

                                {/* Save and Cancel buttons */}
                                <div className="mt-5 sm:mt-6 flex justify-end space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-500 px-6 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:text-sm"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddSalesforceObject;