// ScheduleForm.jsx
import React from 'react';

const ScheduleForm = ({
  frequency,
  setFrequency,
  startDate,
  setStartDate,
  handleAddRun,
  onCancel,
}) => {
  return (
    <div className="mt-6 p-4 border rounded-md space-y-4 bg-gray-50">
      {/* Frequency Dropdown */}
      <div>
        <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
          Run this
        </label>
        <select
          id="frequency"
          name="frequency"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
        >
          <option value="hourly">Hourly</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
        </select>
      </div>

      {/* Date Picker */}
      <div>
        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
          Starting on
        </label>
        <input
          type="datetime-local"
          id="startDate"
          name="startDate"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-700"
          onClick={handleAddRun}
        >
          Add
        </button>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ScheduleForm;