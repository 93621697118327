import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCreateRuleOpen, setIsOutOfCreditsOpen } from '../../slices/uiSlice';
import Rule from './Rule.jsx';
import { PlusIcon } from '@heroicons/react/24/outline';
import DashedBox from './DashedBox.jsx';  // Import the updated DashedBox component
import { setSelectedRuleId, clearSelectedRuleId } from '../../slices/selectedRuleIdSlice.js';

const RuleColumn = ({ selectedObject, filteredRules, handleRuleClick }) => {
  const dispatch = useDispatch();
  const selectedRuleId = useSelector((state) => state.selectedRuleId);

  const ruleCount = selectedObject ? filteredRules.length : 0;

  const credits = useSelector((state) => state.orgCredits.credits);

  const handleCreateRuleClick = () => {
    if (credits < 0) {
      dispatch(setIsOutOfCreditsOpen(true)); // Show the OutOfCredits dialog if credits are below 0
    } else {
      dispatch(clearSelectedRuleId()); // Clear the selected rule ID
      dispatch(setIsCreateRuleOpen(true)); // Open the CreateRule modal
    }
  };

    // Handle the edit click for a specific rule
    const handleEditClick = (rule) => {
      dispatch(setSelectedRuleId(rule._id)); // Set the selected rule ID for editing
      dispatch(setIsCreateRuleOpen(true)); // Open the CreateRule modal
    };
  

  return (
    <div className="w-1/4 flex-1 overflow-auto h-[calc(100vh-96px)] mt-2 border border-gray-200 rounded-lg bg-[#f9f9f9] ml-1 pb-10">
      <div className="sticky top-0 z-10 py-2 bg-gradient-to-t from-transparent via-[#f9f9f9]/100 to-[#f9f9f9]">
        <div className="flex justify-between items-center p-2">
          {/* Left side: Rules header */}
          <div className="flex">
            <h2 className="text-xl font-semibold leading-none pl-2">Rules</h2>
            <span className="ml-1 text-sm text-gray-500 leading-none" style={{ marginTop: '4px' }}>
              ({ruleCount})
            </span>
          </div>

          {/* Right side: Button or empty div with the same height */}
          <div className="flex items-center space-x-2" style={{ minHeight: '40px' }}>
            {selectedObject && ruleCount > 0 ? (
              <button
                onClick={handleCreateRuleClick} // Use the new handler function
                className="flex items-center rounded bg-indigo-600 px-2 py-1 text-xsm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                style={{ minHeight: '30px' }}  // Fixed button height
              >
                New Rule
                <PlusIcon className="ml-1 h-4 w-4" aria-hidden="true" />  {/* Smaller icon */}
              </button>
            ) : (
              <div style={{ minHeight: '30px' }}></div>  // Empty div to preserve layout
            )}
          </div>
        </div>
      </div>

      {selectedObject ? (
        <div className="mt-2">
          {ruleCount > 0 ? (
            <ul className="">
            {filteredRules.map((rule) => (
              <Rule
                key={rule._id}
                rule={rule}
                onClick={handleRuleClick}
                isSelected={rule._id === selectedRuleId}
                onEditClick={handleEditClick} // Pass handleEditClick to each Rule component
              />
            ))}
          </ul>
          ) : (
            // Use the DashedBox component when no rules exist and show the ruler icon
            <DashedBox
              onClick={() => dispatch(setIsCreateRuleOpen(true))}
              isButton={true}
              showIcon={true}
              message="Create a Rule"
            />
          )}
        </div>
      ) : (
        // Use the DashedBox component when no Salesforce object is selected, without the icon
        <DashedBox
          isButton={false}
          showIcon={false}
          message="Select a Salesforce Object"
        />
      )}
    </div>
  );
};

export default RuleColumn;