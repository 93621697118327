import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

// Async thunk for fetching organization data
export const fetchOrganization = createAsyncThunk(
  'organization/fetchOrganization',
  async () => {
    const response = await axiosInstance.get('/api/get-organization');
    return response.data;
  }
);

// Slice for organization
const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    data: null, // Initially no organization data
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganization.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default organizationSlice.reducer;