import React from 'react';

const IssueFixInfo = ({ issue }) => {
  const formatNumber = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString(); // Add commas to numbers
    }
    return value;
  };

  return (
    <div>
      <p>Issue ID: {issue._id}</p>
      {issue.fix && issue.fix.conditions && issue.fix.conditions.length > 0 ? (
        [...issue.fix.conditions] // Create a shallow copy of the conditions array
          .sort((a, b) => a.priorityOrder - b.priorityOrder) // Sort conditions by priorityOrder
          .map((condition, index) => (
            <div key={index} className="mt-4">
              <p><strong>Condition {condition.priorityOrder}:</strong> {condition.conditionDescription} <strong> {condition.evaluation}</strong></p>
              <ul className="ml-4">
                {condition.fieldConditions.map((fieldCondition, idx) => (
                  <li key={idx} className="mb-2">
                    <p><strong>Field Key:</strong> {fieldCondition.fieldConditionKey}</p>
                    <p><strong>Type:</strong> {fieldCondition.fieldConditionType}</p>
                    <p><strong>Field Value:</strong> {['number', 'currency'].includes(fieldCondition.fieldType) ? formatNumber(fieldCondition.fieldValue) : fieldCondition.fieldValue}</p>
                    <p><strong>Condition Value:</strong> {['number', 'currency'].includes(fieldCondition.fieldType) ? formatNumber(fieldCondition.conditionValue) : fieldCondition.conditionValue}</p>
                    <p><strong>Operator:</strong> {fieldCondition.operator}</p>
                    <p><strong>Field Evaluation:</strong> {fieldCondition.evaluation !== undefined ? fieldCondition.evaluation.toString() : 'N/A'}</p>
                    {fieldCondition.newValue && (
                      <p><strong>New Value:</strong> {fieldCondition.newValue}</p>
                    )}
                    {fieldCondition.evaluationReason && (
                      <p><strong>Evaluation Reason:</strong> {fieldCondition.evaluationReason}</p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))
      ) : (
        <p>No conditions available.</p>
      )}
    </div>
  );
};

export default IssueFixInfo;