import React from 'react';

const AuditTableHeader = ({ isAllSelected, onSelectAll }) => {
  return (
    <thead>
      <tr>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-2 pr-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-1/3">
          Rule Name
        </th>
        <th className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell w-1/8">
          Type
        </th>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-2 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-1/8">
          Status
        </th>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-2 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-1/8">
          Issues 
          <br />
          Resolved
        </th>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-2 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-1/8">
          Credits
        </th>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-2 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter w-1/8">
          Completed
        </th>
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-2 backdrop-blur backdrop-filter w-1/8">
          <span className="sr-only">Actions</span>
        </th>
        <th className="w-12 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 px-2 text-center">
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={onSelectAll}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
        </th>
      </tr>
    </thead>
  );
};

export default AuditTableHeader;