import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

const DashedBox = ({ onClick, isButton, message, showIcon }) => {
  const Wrapper = isButton ? 'button' : 'div';

  return (
    <div className="p-4"> {/* Padding around the DashedBox */}
      <Wrapper
        onClick={onClick}
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {showIcon && (
          <PlusIcon className="mx-auto h-12 w-12 text-indigo-500" aria-hidden="true" />
        )}
        <span className="mt-2 block text-sm font-semibold text-gray-900">{message}</span>
      </Wrapper>
    </div>
  );
};

export default DashedBox;