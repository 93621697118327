import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline';
import { setIsLiveActive, setIsScheduleActive, setIsAuditActive, setIsSettingsActive } from '../../slices/uiSlice';
import { setSelectedObject } from '../../slices/selectedObjectSlice';

const Admin = () => {
  const [isListVisible, setIsListVisible] = useState(true);
  const dispatch = useDispatch();
  const isLiveActive = useSelector((state) => state.ui.isLiveActive);
  const isScheduleActive = useSelector((state) => state.ui.isScheduleActive);
  const isAuditActive = useSelector((state) => state.ui.isAuditActive);
  const isSettingsActive = useSelector((state) => state.ui.isSettingsActive);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const handleLiveClick = () => {
    dispatch(setIsLiveActive(true));
    dispatch(setSelectedObject(null));
    dispatch(setIsScheduleActive(false));
    dispatch(setIsAuditActive(false));
    dispatch(setIsSettingsActive(false));
  };

  const handleScheduleClick = () => {
    dispatch(setIsScheduleActive(true));
    dispatch(setIsLiveActive(false));
    dispatch(setSelectedObject(null));
    dispatch(setIsAuditActive(false));
    dispatch(setIsSettingsActive(false));
  };

  const handleAuditClick = () => {
    dispatch(setIsAuditActive(true));
    dispatch(setIsLiveActive(false));
    dispatch(setIsScheduleActive(false));
    dispatch(setSelectedObject(null));
    dispatch(setIsSettingsActive(false));
  };

  const handleSettingsClick = () => {
    dispatch(setIsSettingsActive(true)); // Open Settings component
    dispatch(setIsLiveActive(false));
    dispatch(setIsScheduleActive(false));
    dispatch(setIsAuditActive(false));
    dispatch(setSelectedObject(null));
  };

  return (
    <div className="mt-8">
      <button
        onClick={toggleListVisibility}
        className="flex items-center w-full text-left text-gray-900 rounded hover:text-[#8A79EC]"
      >
        <span className="ml-3 font-bold">Admin</span>
        {isListVisible ? (
          <ChevronUpIcon className="h-3 w-3 inline-block ml-2" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="h-3 w-3 inline-block ml-2" aria-hidden="true" />
        )}
      </button>
      {isListVisible && (
        <ul className="p-2 pl-4">
          <li>
            <button
              className={`flex items-center w-full text-left text-gray-900 text-sm py-2 px-1 ml-1 hover:text-[#8A79EC] ${
                isLiveActive ? 'font-bold text-indigo-600' : ''
              }`}
              onClick={handleLiveClick}
            >
              <l-quantum size="25" speed="7.0" color="#8A79EC"></l-quantum>
              <span className="ml-2">Live</span>
            </button>
          </li>
          <li>
            <button
              className={`flex items-center w-full text-left text-gray-900 text-sm p-2 hover:text-[#8A79EC] ${
                isScheduleActive ? 'font-bold text-indigo-600' : ''
              }`}
              onClick={handleScheduleClick}
            >
              <CalendarDaysIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
              Schedule
            </button>
          </li>
          <li>
            <button
              className={`flex items-center w-full text-left text-gray-900 text-sm p-2 hover:text-[#8A79EC] ${
                isAuditActive ? 'font-bold text-indigo-600' : ''
              }`}
              onClick={handleAuditClick}
            >
              <MagnifyingGlassCircleIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
              Audit
            </button>
          </li>
          <li>
            <button
              className={`flex items-center w-full text-left text-gray-900 text-sm p-2 hover:text-[#8A79EC] ${
                isSettingsActive ? 'font-bold text-indigo-600' : ''
              }`}
              onClick={handleSettingsClick}
            >
              <Cog6ToothIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
              Settings
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Admin;