import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const DeleteRuns = ({ selectedRuns, onConfirmDelete, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(true); // Show modal initially
  const [loading, setLoading] = useState(false); // Track loading state

  const handleConfirmDelete = async () => {
    setLoading(true); // Start loading

    // Call the delete function and wait for the response
    await onConfirmDelete(selectedRuns);

    setLoading(false); // Stop loading after the deletion
    setIsModalOpen(false); // Close the modal
    onClose(); // Trigger the parent close action
  };

  return (
    <Dialog open={isModalOpen} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex items-center justify-center gap-2 sm:flex sm:items-center sm:justify-start">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
              </div>
              <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900 text-center sm:text-left">
                Delete Runs
              </DialogTitle>
            </div>
            <div className="mt-4 text-center sm:text-left">
              <p className="text-gray-600 mb-8 ml-6">
                Are you sure you want to delete {selectedRuns.length} runs? The audit trail for these runs will be deleted permanently. This action is not reversible.
              </p>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-center items-center">
              <button
                type="button"
                onClick={handleConfirmDelete} // Trigger delete action
                disabled={loading} // Disable button when loading
                className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                  loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-600 hover:bg-red-500'
                }`}
              >
                Delete permanently
              </button>
              <button
                type="button"
                onClick={onClose} // Close modal without action
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
            {loading && (
              <div className="flex justify-center items-center mt-4">
                <l-line-wobble size="200" stroke="5" bg-opacity="0.1" speed="1.75" color="#8A79EC"></l-line-wobble>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteRuns;