import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

// Async thunk for fetching organization credits
export const fetchOrgCredits = createAsyncThunk(
  'orgCredits/fetchOrgCredits',
  async () => {
    const response = await axiosInstance.get('/api/get-org-credits');
    return response.data;
  }
);

const orgCreditsSlice = createSlice({
  name: 'orgCredits',
  initialState: {
    credits: null, 
    status: 'idle', 
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgCredits.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrgCredits.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.credits = action.payload.credits; 
      })
      .addCase(fetchOrgCredits.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default orgCreditsSlice.reducer;