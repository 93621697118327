import React from 'react';
import moment from 'moment'; // Optional: use moment.js for formatted timestamps
import SalesforceObjectIcon from '../rules/SalesforceObjectIcon';
import { WrenchIcon } from '@heroicons/react/24/outline';

const FixEvent = ({ event, onComplete }) => {
  const ruleName = event.ruleName || 'Unknown Rule';
  const issueCount =
    event.issueCount !== undefined ? event.issueCount : 'an unknown number of';
  const fixCount =
    event.fixCount !== undefined ? event.fixCount : 'an unknown number of';
  const timestamp = event.timestamp || new Date();

  return (
    <div className="p-2 bg-[#f9f9f9] border rounded-lg mb-2 flex items-center justify-between">

      {/* Icons div */}
      <div className="flex items-center mr-2 flex-shrink-0">
        <WrenchIcon
          className="h-4 w-4 text-indigo-600 mr-1"
          aria-hidden="true"
        />
        <SalesforceObjectIcon objectName={event.salesforceObject} />
      </div>

      {/* Fix text div */}
      <div className="flex-grow mx-2">
        <span className="font-bold">Fix:</span> {' '}
        <span className="font-semibold text-indigo-600">{ruleName}</span> fix was run and recommended
        <div className="inline-block p-1 pl-2 ml-1 pr-2 rounded border">
          <span className="font-mono font-semibold text-gray-900">
            {fixCount}
          </span>{' '}
          <span className="font-mono text-indigo-600 font-semibold">fixes</span>
        </div> for{' '}
        <div className="inline-block p-1 pl-2 ml-1 pr-2 font-mono font-semibold rounded border">
          <span className="font-mono font-semibold text-gray-900">
            {issueCount}
          </span>{' '}
          <span className="font-mono text-red-700 font-semibold">issues</span>
        </div>
      </div>

      {/* Timestamp div */}
      <div className="text-right ml-2 flex-shrink-0">
        <p className="font-semibold text-gray-900 text-center">
          {moment(timestamp).format('h:mm:ss a')}
        </p>
        <p className="text-gray-600">
          {moment(timestamp).format('MMMM Do YYYY')}
        </p>
      </div>

    </div>
  );
};

export default FixEvent;