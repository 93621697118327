// src/slices/selectedObjectSlice.js
import { createSlice } from '@reduxjs/toolkit';

const selectedObjectSlice = createSlice({
  name: 'selectedObject',
  initialState: null,
  reducers: {
    setSelectedObject: (state, action) => action.payload,
  },
});

// src/slices/selectedObjectSlice.js
export const selectObjectByName = (state, objectName) => {
  // Check if selectedObject and objects are defined before accessing them
  return state.selectedObject && state.selectedObject.objects
    ? state.selectedObject.objects.find((object) => object.name === objectName)
    : null;
};

export const { setSelectedObject } = selectedObjectSlice.actions;
export default selectedObjectSlice.reducer;