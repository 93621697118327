// utils/errorLogger.js

import axios from 'axios';

const logErrorToServer = (error, errorInfo) => {
  // Construct the payload
  const errorDetails = {
    message: error.toString(),
    stack: errorInfo ? errorInfo.componentStack : '',
    url: window.location.href,
    timestamp: new Date().toISOString(),
  };

  // Send error details to your server or logging service
  axios.post('/api/log-error', errorDetails).catch((err) => {
    console.error('Failed to send error to server', err);
  });
};

export default logErrorToServer;