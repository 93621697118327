import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsProvisioning } from '../../slices/uiSlice.js'; // Import the action

const Login = () => {
  const dispatch = useDispatch(); // Initialize useDispatch
  const [isAdminChecked, setIsAdminChecked] = useState(false); // Admin permission checkbox state
  const [isTermsChecked, setIsTermsChecked] = useState(false); // Terms & Conditions checkbox state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state

  const handleLogin = () => {
    if (!isAdminChecked) {
      setErrorMessage(
        <>
          tarnData is built for Salesforce administrators with permission to manage all (or most) data.
          <br />
          <br />
          Please stay tuned for the release of <strong>tarnChat</strong>, a simple chat interface built for everyday Salesforce users! Coming Winter 2025.
        </>
      );
      return;
    }
    
    if (isAdminChecked && !isTermsChecked) {
      setErrorMessage('You must agree to the Terms & Conditions to use this application.');
      return;
    }

    // Hide the error message if both checkboxes are checked
    setErrorMessage('');

    // Dispatch the action to set provisioning state to true
    dispatch(setIsProvisioning(true));

    // Determine the backend URL based on the environment
    const backendUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://server.tarn.co/auth/salesforce' // Production URL
        : 'http://localhost:5001/auth/salesforce'; // Local development URL

    // Redirect the user to the backend route to start the Salesforce login flow
    window.location.href = backendUrl;
  };

  return (
    <div className="min-h-screen flex flex-col justify-center bg-[#f9f9f9]">
      <div className="sm:mx-auto pr-2 sm:w-full sm:max-w-md">
        <img
          alt="tarn.co"
          src="/assets/Data_Logo_01.png"
          className="mx-auto h-16 w-auto"
        />
      </div>

      <div className="mx-auto w-1/2">
        <div className="px-6 py-12 sm:rounded-lg sm:px-12">
          {/* Text stacked above button */}
          <div className="space-y-6 text-center">

            {/* Checkbox Wrapper */}
            <div className="w-full sm:w-[90%] mx-auto rounded-lg border border-gray-300 p-4 space-y-4">
              {/* Checkbox for administrator confirmation */}
              <div className="flex justify-center items-center space-x-2">
                <input
                  type="checkbox"
                  id="adminCheckbox"
                  checked={isAdminChecked}
                  onChange={(e) => setIsAdminChecked(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label htmlFor="adminCheckbox" className="text-gray-900 text-sm">
                  I have permission to manage all data in Salesforce
                </label>
              </div>

              {/* Checkbox for Terms & Conditions */}
              <div className="flex justify-center items-center space-x-2">
                <input
                  type="checkbox"
                  id="termsCheckbox"
                  checked={isTermsChecked}
                  onChange={(e) => setIsTermsChecked(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label htmlFor="termsCheckbox" className="text-gray-900 text-sm">
                  I agree to the{' '}
                  <a href="https://tarn.co/terms-and-conditions" target="_blank" className="text-indigo-600 underline">
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a href="https://tarn.co/data-policy" target="_blank" className="text-indigo-600 underline">
                    Data Policy
                  </a>
                </label>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleLogin}
                className="flex items-center justify-center gap-3 rounded-md bg-white mt-4 px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-auto"
                style={{ maxWidth: '300px' }}
              >
                {/* Salesforce Icon */}
                <img
                  src="/assets/Salesforce_Corporate_Logo_RGB.png"
                  alt="Salesforce Logo"
                  className="h-5 w-auto ml-2"
                />
                <span>Login with Salesforce</span>
              </button>
            </div>

            {/* Conditional Error Message */}
            {errorMessage && (
              <p className="mt-6 text-sm text-red-600 text-center">
                {errorMessage}
              </p>
            )}
          </div>
        </div>

        {/* Place this at the end of your component */}
        <div className="absolute bottom-4 inset-x-0 text-center text-sm text-gray-500">
          What is tarn?{' '}
          <a
            href="https://tarn.co/"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 ml-2"
          >
            visit tarn.co
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;