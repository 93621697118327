import React, { useState, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import axiosInstance from '../../axiosInstance.js';
import { ring } from 'ldrs';

const ValidateRule = ({ rule, onValidationStatusChange, shouldValidate, onValidationComplete }) => {
  const [isValidating, setIsValidating] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [validationStatus, setValidationStatus] = useState(null); // Tracks validation status: 'valid', 'invalid', or 'warning'
  const [validationDetails, setValidationDetails] = useState([]); // Array to hold bullet points

  ring.register();

  useEffect(() => {
    const performValidation = async () => {
      if (shouldValidate && rule) {
        await validateRule(rule);
        onValidationComplete(); // Reset shouldValidate in parent component
      } else if (rule && !shouldValidate && validationStatus === null) {
        // Editing an existing rule; show 'Rule is Valid!' without API call
        setValidationMessage('Rule is Valid!');
        setValidationStatus('valid');
        onValidationStatusChange('valid'); // Notify parent component
      }
    };

    performValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldValidate]); // Only depend on shouldValidate

  const validateRule = async (rule) => {
    setIsValidating(true);
    setValidationMessage('Validating Rule...');
    setValidationStatus(null); // Reset validation status
    setValidationDetails([]); // Reset validation details
    onValidationStatusChange(null); // Reset validation status in parent component

    try {
      // Send the entire rule object to the server for validation
      const validationResponse = await axiosInstance.post('/api/validate-rule', { rule });
      console.log('Validation Response:', validationResponse.data);

      const validations = validationResponse.data.validations;
      const filterable = validations.find((v) => v.name === 'filterable');
      const crossObject = validations.find((v) => v.name === 'crossObject');
      const salesforceAPI = validations.find((v) => v.name === 'salesforceAPI');

      const bulletPoints = [];
      const nonFilterableFields = []; // To hold all field names with filterable = false

      // Handle filterable validation result
      if (filterable && filterable.result === false) {
        // If filterable fails, show the appropriate message for each field
        filterable.data.forEach((field) => {
          bulletPoints.push(
            <React.Fragment key={field.fieldName}>
              The <strong>{field.fieldName}</strong> field can’t be used in Rules because Salesforce does not allow filtering on {field.type} fields.
            </React.Fragment>
          );
          nonFilterableFields.push(field.fieldName);
        });

        // Create a combined bullet point for non-filterable fields
        if (nonFilterableFields.length > 0) {
          const fieldsList = nonFilterableFields.join(', ');
          bulletPoints.push(
            <React.Fragment key="nonFilterableFields">
              Instead, you can (and should!) use the <strong>{fieldsList}</strong> fields when creating your Fix for this Rule.
            </React.Fragment>
          );
        }

        // Handle crossObject validation when filterable fails
        if (crossObject && crossObject.result === true) {
          bulletPoints.push(
            "A Rule based on multiple Salesforce objects is not fully supported. We’re working to support this as soon as possible."
          );
          bulletPoints.push("Rules are best built off one Salesforce object at a time.");
        }

        setValidationMessage('Rule Validation Failed');
        setValidationStatus('invalid');
        setValidationDetails(bulletPoints);
        onValidationStatusChange('invalid');
        return; // Stop further validation
      }

      // Handle crossObject validation when filterable passes but crossObject fails
      if (
        filterable.result === true &&
        crossObject &&
        crossObject.result === true &&
        salesforceAPI &&
        salesforceAPI.result === false
      ) {
        bulletPoints.push(
          "A Rule based on multiple Salesforce objects is not fully supported. We’re working to support this as soon as possible."
        );
        bulletPoints.push("Rules are best built off one Salesforce object at a time.");

        setValidationMessage('Rule Validation Failed');
        setValidationStatus('invalid');
        setValidationDetails(bulletPoints);
        onValidationStatusChange('invalid');
        return; // Stop further validation
      }

      // Handle crossObject result and salesforceAPI result being true (Warning case)
      if (
        crossObject &&
        crossObject.result === true &&
        salesforceAPI &&
        salesforceAPI.result === true
      ) {
        bulletPoints.push(
          "Rules based on multiple Salesforce objects are not fully supported (yet), but this one may work."
        );
        bulletPoints.push("Rules are best built off one Salesforce object at a time.");

        setValidationMessage('Rule Validation Warning');
        setValidationStatus('warning');
        setValidationDetails(bulletPoints);
        onValidationStatusChange('warning', validations);
        return; // Stop further validation
      }

      // If everything passes
      setValidationMessage('Rule is Valid!');
      setValidationStatus('valid');
      setValidationDetails([]);
      onValidationStatusChange('valid', validations);

    } catch (error) {
      console.error('Error validating rule:', error);
      setValidationMessage('Rule Validation Failed');
      setValidationStatus('invalid');
      setValidationDetails(['There was an error during rule validation.']);
      onValidationStatusChange('invalid');
    } finally {
      setIsValidating(false);
      onValidationComplete(); // Reset shouldValidate
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-2 text-gray-600 h-full w-full">
      <div className="flex items-center justify-center text-xsm font-semibold">
        {validationMessage}
        {isValidating && (
          <l-ring
            size="20"
            stroke="5"
            bg-opacity="0"
            speed="2"
            color="black"
          ></l-ring>
        )}
        {validationStatus === 'valid' && (
          <CheckCircleIcon
            className="h-5 w-5 text-green-600 ml-1"
            aria-hidden="true"
          />
        )}
        {validationStatus === 'invalid' && (
          <XCircleIcon
            className="h-5 w-5 text-red-600 ml-1"
            aria-hidden="true"
          />
        )}
        {validationStatus === 'warning' && (
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-500 ml-1"
            aria-hidden="true"
          />
        )}
      </div>

      {/* Validation Details */}
      <ul className="list-disc pl-5 space-y-2 text-xs">
        {validationDetails.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
  );
};

export default ValidateRule;