import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

export const fetchIssues = createAsyncThunk(
  'issues/fetchIssues',
  async () => {
    const response = await axiosInstance.get('/api/issues');
    return response.data;
  }
);

// Fetch issues by ruleId using axiosInstance
export const fetchIssuesByRuleId = createAsyncThunk(
  'issues/fetchIssuesByRuleId',
  async (ruleId) => {
    const response = await axiosInstance.get(`/api/issues/rule/${ruleId}`);
    return { ruleId, issues: response.data };
  }
);

// Fetch a single issue by issueId using axiosInstance
export const fetchIssueById = createAsyncThunk(
  'issues/fetchIssueById',
  async (issueId) => {
    const response = await axiosInstance.get(`/api/issues/${issueId}`);
    return response.data;
  }
);

// Slice for issues
const issuesSlice = createSlice({
  name: 'issues',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIssues.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIssues.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchIssues.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchIssuesByRuleId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIssuesByRuleId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Replace issues related to the specific ruleId
        state.data = state.data.filter(issue => issue.ruleId !== action.payload.ruleId).concat(action.payload.issues);
      })
      .addCase(fetchIssuesByRuleId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchIssueById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIssueById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Replace the specific issue in the state
        state.data = state.data.map(issue => issue._id === action.payload._id ? action.payload : issue);
      })
      .addCase(fetchIssueById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default issuesSlice.reducer;