// HeroIcon.jsx
import React from 'react';
import * as SolidIcons from '@heroicons/react/24/solid'; // Import all solid Heroicons
import * as OutlineIcons from '@heroicons/react/24/outline'; // Import all outline Heroicons

const HeroIcon = ({ iconName, type = 'solid', className = 'h-6 w-6 text-gray-500' }) => {
  // Choose between solid or outline icons based on the type prop
  const Icons = type === 'solid' ? SolidIcons : OutlineIcons;
  const IconComponent = Icons[iconName]; // Dynamically select the Heroicon component based on the name and type

  // Fallback to a default icon if the provided name does not exist
  if (!IconComponent) {
    console.warn(`Icon with name "${iconName}" not found in ${type} icons.`);
    return <SolidIcons.QuestionMarkCircleIcon className={className} />;
  }

  return <IconComponent className={className} />;
};

export default HeroIcon;