import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOldDataOpen } from '../../slices/uiSlice';

const OldData = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isOldDataOpen);

  const handleClose = () => {
    dispatch(setIsOldDataOpen(false));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-md text-center">
        <h2 className="text-xl font-semibold text-gray-900">Issues Expired</h2>
        <p className="mt-2 text-gray-700">
          Your are issues are more than 10 minutes old! <br /> Data moves fast. Run the rule again to get a fresh set of issues.
        </p>
        <button
          onClick={handleClose}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500"
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default OldData;