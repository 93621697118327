import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance.js';
import packageJson from '../../../package.json';

const CreateRuleFeedback = ({ previewRule, prompt, onClose }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFeedback = async () => {
    setIsSubmitting(true);

    try {
      const data = {
        feedback: feedbackText,
        previewRule,
        prompt,  // Include prompt in the feedback data
        version: packageJson.version,
      };

      // Send feedback to the backend
      const response = await axiosInstance.post('/api/rule-feedback', data);

      // Reset feedback text and close the component
      setFeedbackText('');
      onClose();
    } catch (error) {
      console.error('Error submitting rule feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mt-4 p-4 bg-gray-100 rounded-md">
      <div className="text-sm text-gray-900 mb-2">
        Tell us what you expected. This submission will attach all the details above.
      </div>
      <textarea
        id="feedback"
        name="feedback"
        rows={4}
        className="block w-full rounded-md border border-gray-300 p-2 mb-2 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
        disabled={isSubmitting}
      />
      <div className="mt-2 flex justify-end space-x-2">
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
            isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500'
          }`}
          onClick={submitFeedback}
          disabled={isSubmitting}
        >
          Send Feedback
        </button>
      </div>
    </div>
  );
};

export default CreateRuleFeedback;