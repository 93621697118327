import React from 'react';
import { PlayIcon, TrashIcon, PencilSquareIcon, WrenchIcon, CalendarIcon, ForwardIcon } from '@heroicons/react/24/outline';
import { BoltIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux'; // Import useSelector
import { fetchIssuesByRuleId } from '../../slices/issuesSlice.js';
import { fetchRuleById, fetchRules } from '../../slices/rulesSlice.js';
import { addNotification } from '../../slices/notificationSlice.js';
import { clearSelectedRuleId, setSelectedRuleId } from '../../slices/selectedRuleIdSlice.js';
import { lineWobble } from 'ldrs';
import axiosInstance from '../../axiosInstance.js';
import { fetchOrgCredits } from '../../slices/orgCreditsSlice.js';
import { setIsOutOfCreditsOpen, setIsCreateRuleOpen, setIsPreventRunOpen, setPreventRunMessage } from '../../slices/uiSlice.js';
import { addRunningRule, removeRunningRule } from '../../slices/runningSlice.js'; // Import running actions

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Rule = ({ rule, onClick, isSelected }) => {
  const dispatch = useDispatch();
  const credits = useSelector((state) => state.orgCredits.credits);

  // Register the lineWobble animation
  lineWobble.register();

  // Remove local isRunning state
  // const [isRunning, setIsRunning] = useState(false);

  // Get isRunning from Redux state
  const isRunning = useSelector((state) => state.running.runningRuleIds.includes(rule._id));

  const handlePlayClick = async (e) => {
    e.stopPropagation();

    // Check if credits are below 0
    if (credits < 0) {
      // Open OutOfCredits dialog and prevent API call
      dispatch(setIsOutOfCreditsOpen(true));
      return; // Skip running the API call
    }

    dispatch(addRunningRule(rule._id)); // Add rule to running state
    dispatch(setSelectedRuleId(rule._id));

    try {
      const response = await axiosInstance.post('/api/run-rule', rule);

      // Check if preventRun was triggered and returned a preventResult
      if (response.data.prevent) {
        dispatch(setIsPreventRunOpen(true));
        dispatch(setPreventRunMessage(response.data.message));
        return; // Skip further processing
      }

      console.log('Rule run successfully');

      await dispatch(fetchIssuesByRuleId(rule._id));
      const updatedRule = await dispatch(fetchRuleById(rule._id)).unwrap();

      dispatch(addNotification({
        type: 'success',
        message: 'Rule run successfully',
        description: `Rule: ${updatedRule.ruleName} has detected ${updatedRule.lastRunIssueCount} issues.`,
      }));
      dispatch(fetchOrgCredits());

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (401)
        dispatch(addNotification({
          type: 'error',
          message: 'Authentication Session Expired',
          description: 'Authentication session expired, please log in again to refresh token.',
        }));
      } else {
        // Handle other errors
        console.error('Error running rule:', error);
        dispatch(addNotification({
          type: 'error',
          message: 'Error executing rule',
          description: `Failed to execute the rule: ${rule.ruleName}`,
        }));
      }
    } finally {
      dispatch(removeRunningRule(rule._id)); // Remove rule from running state
    }
  };

  const handleDeleteClick = async (e) => {
    e.stopPropagation();
    try {
      await axiosInstance.put(`/api/delete-rule/${rule._id}`);

      dispatch(addNotification({
        type: 'success',
        message: 'Rule deleted',
        description: `Rule: ${rule.ruleName} has been successfully deleted.`,
      }));

      await dispatch(clearSelectedRuleId());
      await dispatch(fetchRules());
      await dispatch(fetchIssuesByRuleId(rule._id));

    } catch (error) {
      console.error('Error deleting rule:', error);

      dispatch(addNotification({
        type: 'error',
        message: 'Error deleting rule',
        description: `Failed to delete the rule: ${rule.ruleName}.`,
      }));
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    dispatch(setSelectedRuleId(rule._id));
    dispatch(setIsCreateRuleOpen(true));
  };

  const handleSuperRunClick = async (e) => {
    e.stopPropagation();
    // Check if credits are below 0
    if (credits < 0) {
      // Open OutOfCredits dialog and prevent API call
      dispatch(setIsOutOfCreditsOpen(true));
      return; // Skip running the API call
    }

    dispatch(addRunningRule(rule._id)); // Add rule to running state
    dispatch(setSelectedRuleId(rule._id));

    try {
      await axiosInstance.post('/api/super-run-rule', { ruleId: rule._id });
      console.log('Super run successfully');

      await dispatch(fetchIssuesByRuleId(rule._id));
      const updatedRule = await dispatch(fetchRuleById(rule._id)).unwrap();

      dispatch(addNotification({
        type: 'success',
        message: 'Super Rule run successfully',
        description: `Super Rule: ${updatedRule.ruleName} has detected ${updatedRule.lastRunIssueCount} issues.`,
      }));
      dispatch(fetchOrgCredits());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (401)
        dispatch(addNotification({
          type: 'error',
          message: 'Authentication Session Expired',
          description: 'Authentication session expired, please log in again to refresh token.',
        }));
      } else {
        // Handle other errors
        console.error('Error running rule:', error);

        dispatch(addNotification({
          type: 'error',
          message: 'Error executing rule',
          description: `Failed to execute the rule: ${rule.ruleName}`,
        }));
      }
    } finally {
      dispatch(removeRunningRule(rule._id)); // Remove rule from running state
    }
  };

  const getRelativeDate = (lastRunDate) => {
    const now = new Date();
    const diffTime = Math.abs(now - lastRunDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return "Today";
    } else {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    }
  };

  const colors = ['text-red-700', 'text-blue-700', 'text-green-700', 'text-yellow-700'];

  return (
    <li
      className={classNames(
        "relative flex flex-col justify-between gap-y-2 cursor-pointer pt-3 pb-1 px-1 bg-white",
        isSelected ? 'border-l-4 border-l-indigo-500' : '',
        'border-b border-b-gray-100 last:border-b-0'
      )}
      onClick={() => onClick(rule)}
    >
      <div className="min-w-0 px-2 flex justify-between items-start">
        <div className="flex items-center gap-x-5">
          <p className={classNames(
            "text-xsm leading-6 text-gray-900 hover:text-[#8A79EC]",
            isSelected ? "font-semibold text-indigo-600" : "font-medium"
          )}>
            {rule.ruleName}
          </p>
        </div>
        <div className="flex items-start space-x-1 py-1 pl-2">
          <p
            className={classNames(
              'text-gray bg-[#C2B9F6] ring-1 ring-[#8A79EC]',
              'whitespace-nowrap rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset'
            )}
          >
            {rule.lastRunIssueCount} Issues
          </p>
          {rule.hasFix && <WrenchIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />}
          {rule.hasSchedule && <CalendarIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />}
          {rule.hasAiFix && <BoltIcon className="h-4 w-4 text-indigo-500" aria-hidden="true" />}
        </div>
      </div>

      {isSelected && (
        <div className="min-w-0 px-2 flex flex-wrap gap-y-1 my-1">
          {rule.soqlConditions && rule.soqlConditions.map((condition, index) => (
            <React.Fragment key={index}>
              <div className={`inline-block text-xxs ${colors[index % colors.length]} bg-gray-100 p-1 rounded border font-mono`}>
                <span>{condition}</span>
              </div>
              {index < rule.soqlConditions.length - 1 && (
                <div className="inline-block text-xxs text-gray-500 font-mono m-1">
                  AND
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      <div className="min-w-0 px-2 flex justify-between items-center">
        <p className="text-xxs leading-5 text-gray-500">
          Last run: {rule.lastRun ? (
            <time dateTime={rule.lastRun}>
              <strong>
                {new Date(rule.lastRun)
                  .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                  .split(' ')[0]} {/* Numeric part */}
              </strong>
              {new Date(rule.lastRun)
                .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                .split(' ')[1]}{/* AM/PM part */}
              {','} {/* Comma after AM/PM */}
              {' '}
              {getRelativeDate(new Date(rule.lastRun))}
            </time>
          ) : (
            "Never"
          )}
        </p>
        <div className="flex space-x-1">
          {(rule.hasFix && !rule.hasAiFix) && (
            <button
              className="rounded-md p-1 bg-white text-indigo-500 hover:text-indigo-400"
              onClick={handleSuperRunClick}
              title="Super Run - up to 2000 records"
            >
              <ForwardIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Forward</span>
            </button>
          )}

          {/* Play Button */}
          <button
            className="rounded-md p-1 bg-white text-gray-900 hover:text-[#8A79EC]"
            onClick={handlePlayClick}
            title="Run Rule"
          >
            <PlayIcon className="h-4 w-4" aria-hidden="true" />
            <span className="sr-only">Play</span>
          </button>

          {/* Edit Button */}
          <button
            className="text-gray-900 p-1 hover:text-[#8A79EC]"
            onClick={handleEditClick}>
            <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>

          {/* Delete Button */}
          <button
            className="rounded-md bg-white p-1 text-gray-900 hover:text-[#8A79EC]"
            onClick={handleDeleteClick}
            title="Delete Rule"
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
            <span className="sr-only">Delete</span>
          </button>
        </div>
      </div>

      {/* Display lineWobble animation if rule is running */}
      {isRunning && (
        <div className="flex justify-center items-center mt-0">
          <l-line-wobble
            size="200"
            stroke="5"
            bg-opacity="0.1"
            speed="1.75"
            color="#8A79EC"
          ></l-line-wobble>
        </div>
      )}
    </li>
  );
};

export default Rule;