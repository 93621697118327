import React from 'react';
import {
  BanknotesIcon as BanknotesIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  UserCircleIcon as UserCircleIconSolid,
  RocketLaunchIcon as RocketLaunchIconSolid,
  DocumentMagnifyingGlassIcon as DocumentMagnifyingGlassIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
} from '@heroicons/react/24/solid';

const SalesforceObjectIcon = ({ objectName, className }) => {
  switch (objectName) {
    case 'Opportunity':
      return <BanknotesIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Account':
      return <BuildingOffice2IconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Contact':
      return <UserCircleIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Lead':
      return <UserCircleIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Campaign':
      return <RocketLaunchIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Case':
      return <DocumentMagnifyingGlassIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Product2':
      return <ShoppingCartIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    case 'Contract':
      return <DocumentTextIconSolid className={`h-6 w-6 inline-block text-[#8A79EC] ${className}`} aria-hidden="true" />;
    default:
      return null;
  }
};

export default SalesforceObjectIcon;