import { configureStore } from '@reduxjs/toolkit';
import salesforceObjectsReducer from '../slices/salesforceObjectsSlice.js';
import selectedObjectReducer from '../slices/selectedObjectSlice.js';
import rulesReducer from '../slices/rulesSlice.js';
import issuesReducer from '../slices/issuesSlice.js';
import selectedRuleIdReducer from '../slices/selectedRuleIdSlice.js';
import uiReducer from '../slices/uiSlice.js';
import notificationsReducer from '../slices/notificationSlice.js';
import userReducer from '../slices/userSlice.js';
import organizationReducer from '../slices/organizationSlice.js';
import orgCreditsReducer from '../slices/orgCreditsSlice.js';
import teamReducer from '../slices/teamSlice.js';
import runningReducer from '../slices/runningSlice.js'; // Import the running slice

const store = configureStore({
  reducer: {
    salesforceObjects: salesforceObjectsReducer,
    selectedObject: selectedObjectReducer,
    rules: rulesReducer,
    issues: issuesReducer,
    selectedRuleId: selectedRuleIdReducer,
    ui: uiReducer,
    notifications: notificationsReducer,
    user: userReducer,
    organization: organizationReducer,
    team: teamReducer,
    orgCredits: orgCreditsReducer,
    running: runningReducer, // Add the running slice
  },
});

export default store;