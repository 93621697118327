import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

// Async thunk for fetching team data
export const fetchTeam = createAsyncThunk(
  'team/fetchTeam',
  async () => {
    const response = await axiosInstance.get('/api/get-team');
    console.log("Team response from API:", response.data); // Log API response
    return response.data;
  }
);

// Slice for team
const teamSlice = createSlice({
  name: 'team',
  initialState: {
    data: [], // Initially no team data
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeam.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTeam.fulfilled, (state, action) => {
        console.log("Payload received in teamSlice:", action.payload); // Log payload in slice
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTeam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default teamSlice.reducer;