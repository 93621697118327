import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { removeNotification } from '../slices/notificationSlice';

const Notification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications);

    useEffect(() => {
        notifications.forEach(notification => {
            const timer = setTimeout(() => {
                dispatch(removeNotification(notification.id));
            }, 5000);

            return () => clearTimeout(timer);
        });
    }, [notifications, dispatch]);

    const getIcon = (type) => {
        switch (type) {
            case 'success':
                return <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />;
            case 'error':
                return <ExclamationCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />;
            case 'info':
            default:
                return <InformationCircleIcon aria-hidden="true" className="h-6 w-6 text-red-400" />;
        }
    };

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed z-50 inset-0 flex items-end justify-end px-4 py-6 sm:items-end sm:justify-end sm:p-6"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {notifications.map(notification => (
                    <Transition
                        key={notification.id}
                        show={true}
                        enter="transform transition duration-300"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transform transition duration-300"
                        leaveFrom="translate-y-0 opacity-100 sm:translate-x-0"
                        leaveTo="translate-y-0 opacity-0 sm:translate-x-2"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {getIcon(notification.type)}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                                        <p className="mt-1 text-sm text-gray-500">{notification.description}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            onClick={() => dispatch(removeNotification(notification.id))}
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                ))}
            </div>
        </div>
    );
};

export default Notification;