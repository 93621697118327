// fetchSchedules.js
import axiosInstance from '../../../axiosInstance.js'; // Adjust the path as needed
import parser from 'cron-parser'; // Import cron-parser to handle cron expressions

export const fetchSchedules = async (rules) => {
  try {
    const response = await axiosInstance.get('/api/get-org-schedules');
    console.log('Fetched Schedules:', response.data);

    // Array to hold all projected runs
    const projectedRuns = [];

    // Iterate through the fetched schedules
    if (Array.isArray(response.data)) {
      const now = new Date();
      const fourteenDaysLater = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);

      response.data.forEach((schedule) => {
        if (schedule && schedule.nextRun) {
          // Find the corresponding rule using ruleId
          const rule = rules.find((r) => r._id === schedule.ruleId);

          if (!rule) {
            console.warn(`Rule with ID ${schedule.ruleId} not found for schedule ${schedule._id}`);
            return;
          }

          let nextRun = new Date(schedule.nextRun);
          let isFirstRun = true;

          // Project runs for the next 14 days using the cron expression
          while (nextRun <= fourteenDaysLater) {
            projectedRuns.push({
              ...schedule,
              projectedRun: new Date(nextRun),
              ruleName: rule.ruleName,
              salesforceObject: rule.salesforceObject, // Include the ruleName
              isNextRun: isFirstRun, // Flag the first run
            });

            isFirstRun = false;

            // Use cron-parser to calculate the next run time
            try {
              const interval = parser.parseExpression(schedule.cronExpression, { currentDate: nextRun });
              nextRun = interval.next().toDate();
            } catch (err) {
              console.error(`Invalid cron expression for schedule ID ${schedule._id}: ${err.message}`);
              break; // Exit the loop if the cron expression is invalid
            }
          }
        }
      });

      // Sort projectedRuns by projectedRun time in ascending order
      projectedRuns.sort((a, b) => new Date(a.projectedRun) - new Date(b.projectedRun));

      console.log('Sorted Projected Runs:', projectedRuns);
      return projectedRuns;
    } else {
      console.error('Unexpected data format:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching schedules:', error);
    return [];
  }
};