import React, { useState, useEffect } from 'react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import packageJson from '../../package.json'; // Import the version from package.json

const ReleaseNotesNotif = () => {
    const version = packageJson.version; // Get the version from package.json
    const [isVisible, setIsVisible] = useState(true); // State to track visibility of the notification

    // Effect to check local storage for dismissal status on component mount
    useEffect(() => {
        const isDismissed = localStorage.getItem(`release-notes-dismissed-${version}`); // Check for dismissal of this specific version
        if (isDismissed === 'true') {
            setIsVisible(false); // Hide notification if dismissed previously
        }
    }, [version]);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem(`release-notes-dismissed-${version}`, 'true'); // Persist dismissal state in local storage
    };

    // If not visible, return null to avoid rendering the component
    if (!isVisible) return null;

    return (
        <div className="rounded-md bg-blue-50 p-3 pt-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                        You're working in <strong>Version {version}</strong>! See what’s new in the{' '}
                        <a
                            href="https://tarn.co/release-notes"
                            className="font-medium text-blue-700 underline hover:text-blue-600 cursor-pointer"
                            target="_blank" // Opens link in a new tab
                            rel="noopener noreferrer" // Prevents security risks when opening in a new tab
                        >
                            release notes
                        </a>.
                    </p>
                </div>
                {/* Close button */}
                <div className="ml-4 flex-shrink-0">
                    <button
                        type="button"
                        className="inline-flex pr-2 rounded-md bg-blue-50 text-blue-400 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600"
                        onClick={handleDismiss} // Call the dismiss function
                    >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReleaseNotesNotif;