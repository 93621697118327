// fetchRuleSchedules.js
import axiosInstance from '../../../axiosInstance.js';

const fetchRuleSchedules = async (ruleId) => {
  try {
    const response = await axiosInstance.get('/api/get-rule-schedules', {
      params: { ruleId },
    });

    const schedules = response.data;

    console.log('Fetched schedules:', schedules);

    // Normalize the schedule data
    const mappedSchedules = schedules.map((schedule) => {
      // Use the new frequency field
      const frequency = schedule.frequency;

      // Reconstruct startDate from schedule properties
      let startDate;

      if (schedule.frequency === 'hourly') {
        // Use nextRun or createdOn as the start date
        startDate = schedule.nextRun || schedule.createdOn;
      } else if (schedule.frequency === 'daily' || schedule.frequency === 'weekly') {
        // For daily and weekly, reconstruct the startDate from hour, minute, ampm, and dayOfWeek
        const date = new Date(); // Start with the current date
        let hours = schedule.hour;
        if (schedule.ampm === 'PM' && hours < 12) {
          hours += 12;
        } else if (schedule.ampm === 'AM' && hours === 12) {
          hours = 0;
        }
        date.setHours(hours, schedule.minute, 0, 0);

        if (schedule.frequency === 'weekly') {
          // Adjust to the correct day of the week
          const dayOfWeekMap = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
          };
          const targetDay = dayOfWeekMap[schedule.dayOfWeek];
          const currentDay = date.getDay();
          const dayDifference = (targetDay - currentDay + 7) % 7;
          date.setDate(date.getDate() + dayDifference);
        }

        startDate = date.toISOString();
      } else {
        startDate = schedule.nextRun || schedule.createdOn;
      }

      return {
        ...schedule,
        frequency, // Retain the updated frequency field
        startDate, // Include the reconstructed startDate
      };
    });

    return mappedSchedules;
  } catch (error) {
    console.error('Error fetching rule schedules:', error);
    throw error;
  }
};

export default fetchRuleSchedules;