import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CreateIssuePreview = ({ validations, rule, onIssueLayoutChange }) => {
  const [issueLayout, setIssueLayout] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  // Get selectedObject and salesforceObjects from Redux store
  const selectedObject = useSelector((state) => state.selectedObject);
  const salesforceObjects = useSelector((state) => state.salesforceObjects.data);

  useEffect(() => {
    // Get the available fields from the salesforceObjects without sorting
    if (selectedObject && Array.isArray(salesforceObjects)) {
      const matchedObject = salesforceObjects.find((object) => object.name === selectedObject.name);
      if (matchedObject) {
        const allFieldsList = matchedObject.fields || [];
        setAvailableFields(allFieldsList); // Set fields without sorting
      }
    }
  }, [selectedObject, salesforceObjects]);

// CreateIssuePreview.jsx
useEffect(() => {
    if (rule && rule.issueLayout && issueLayout.length === 0) {
      setIssueLayout(rule.issueLayout);
    }
  }, [rule, issueLayout.length]);

  
// CreateIssuePreview.jsx
const handleFieldChange = (fieldIndex, newFieldName) => {
    const updatedIssueLayout = [...issueLayout];
    const fieldToUpdate = { ...updatedIssueLayout[fieldIndex] };
    const selectedField = availableFields.find(f => f.name === newFieldName);
  
    if (fieldToUpdate && selectedField) {
      fieldToUpdate.label = selectedField.label;
      fieldToUpdate.name = selectedField.name;
      fieldToUpdate.type = selectedField.type;
      const fieldInSoqlFields = rule.soqlFields.some(f => f.name === selectedField.name || f === selectedField.name);
      fieldToUpdate.inQuery = fieldInSoqlFields;
  
      updatedIssueLayout[fieldIndex] = fieldToUpdate;
  
      // Update issueLayout
      setIssueLayout(updatedIssueLayout);
  
      // Call parent handler
      onIssueLayoutChange(updatedIssueLayout);
    }
  };

  return (
    <li className="text-xs p-3 border rounded-lg bg-white flex flex-wrap relative">
      {issueLayout && issueLayout.length > 0 && (
        <>
          {/* Render issueTitle field */}
          <div className="w-full flex items-center mb-4">
            <h4 className="font-semibold text-sm text-indigo-700">Issue Title:</h4>
            <strong className="">
              {/* Picklist for issueTitleField */}
              <select
                className="text-xs ml-4 py-1 px-2 w-full border border-gray-300 rounded-md focus:outline-none appearance-none truncate max-h-28 overflow-y-auto"
                value={issueLayout.find(field => field.location === 'issueTitle')?.name || ''}
                onChange={(e) => {
                  const newFieldName = e.target.value;
                  const index = issueLayout.findIndex(field => field.location === 'issueTitle');
                  handleFieldChange(index, newFieldName);
                }}
              >
                {availableFields.map((fieldOption, idx) => (
                  <option key={idx} value={fieldOption.name}>{fieldOption.label}</option>
                ))}
              </select>
            </strong>
          </div>

          {/* Issue preview fields header */}
          <div className="w-full mb-3 mt-4">
            <h4 className="font-semibold text-sm text-indigo-700">Issue preview fields:</h4>
          </div>

          {/* Render other fields */}
          <div className="w-full grid grid-cols-2 gap-4 text-xs">
            {issueLayout.map((field, index) => {
              if (field.location !== 'issueTitle') {
                return (
                  <div key={index} className="flex items-start space-x-2 w-full">
                    <div className="flex items-center space-x-2">
                      <strong className="w-34 truncate">
                        {/* Picklist for each field */}
                        <select
                          className="text-xs py-1 px-2 w-full border border-gray-300 rounded-md focus:outline-none appearance-none truncate max-h-28 overflow-y-auto"
                          value={field.name}
                          onChange={(e) => {
                            const newFieldName = e.target.value;
                            handleFieldChange(index, newFieldName);
                          }}
                        >
                          {availableFields.map((availField, idx) => (
                            <option key={idx} value={availField.name}>{availField.label}</option>
                          ))}
                        </select>
                      </strong>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </li>
  );
}

export default CreateIssuePreview;