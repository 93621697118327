import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

let notificationIdCounter = 1; // Initialize a counter outside the slice

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      state.push({ id: notificationIdCounter++, ...action.payload }); // Increment the counter for each notification
    },
    removeNotification: (state, action) => {
      return state.filter(notification => notification.id !== action.payload);
    }
  }
});

export const { addNotification, removeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;