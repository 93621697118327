import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import HeroIcon from '../../HeroIcon.jsx';

const CurrentConfig = ({ selectedObjects, onRemoveObject, onSelectObject, selectedObject }) => {
  const objectsToDisplay = selectedObjects || [];

  return (
    <div className="rounded-lg border border-gray-300 bg-[#f9f9f9] p-4 pl-11 mt-4">
      <div className="flex flex-wrap gap-4">
        {objectsToDisplay.length === 0 ? (
          <p>No Salesforce objects added yet.</p>
        ) : (
          objectsToDisplay.map((obj) => (
            <div
              key={obj.name}
              onClick={() => onSelectObject(obj)} // Set the selected object on click
              className={`relative flex flex-col items-center justify-between w-20 h-20 text-xsm rounded-lg shadow-sm border cursor-pointer ${
                obj.newlyAdded ? 'bg-green-100 border-green-400' : 'bg-white border-gray-200'
              } ${selectedObject?.name === obj.name ? 'ring-2 ring-indigo-500' : ''}`} // Highlight selected object
            >
              {obj.newlyAdded && (
                <button
                  className="absolute top-1 right-1 bg-red-400 text-white rounded-full p-1 hover:bg-red-700 focus:outline-none"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the onClick event for the container
                    onRemoveObject(obj);
                  }}
                >
                  <XMarkIcon className="h-2 w-2" aria-hidden="true" />
                </button>
              )}

              {obj.heroIcon && (
                <HeroIcon iconName={obj.heroIcon} className="h-6 w-6 text-gray-500 mb-1 mt-4" />
              )}

              <span className="text-center text-gray-700 mt-auto mb-2">{obj.label}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CurrentConfig;