import React, { useEffect, useState } from 'react';

const SearchObjects = ({ salesforceObjects, onAddObject }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Update the search results when searchQuery changes
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]); // Clear search results if search is empty
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();

      // Filter and sort results based on priority
      const results = salesforceObjects
        .filter(
          (obj) =>
            obj.name.toLowerCase().includes(lowercasedQuery) ||
            obj.label.toLowerCase().includes(lowercasedQuery)
        )
        .sort((a, b) => {
          const aLabel = a.label.toLowerCase();
          const bLabel = b.label.toLowerCase();
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          // Assign weights based on match criteria
          const getMatchWeight = (str) => {
            if (str === lowercasedQuery) return 1; // Exact match
            if (str.startsWith(lowercasedQuery)) return 2; // Starts with search query
            if (str.includes(lowercasedQuery)) return 3; // Contains search query
            return 4; // No match (shouldn't happen in filtered results)
          };

          const aWeight = Math.min(getMatchWeight(aLabel), getMatchWeight(aName));
          const bWeight = Math.min(getMatchWeight(bLabel), getMatchWeight(bName));

          // First sort by match weight, then by label alphabetically if weights are equal
          if (aWeight !== bWeight) {
            return aWeight - bWeight;
          } else {
            return aLabel.localeCompare(bLabel);
          }
        });

      setSearchResults(results);
    }
  }, [searchQuery, salesforceObjects]);

  // Handle item selection
  const handleSelectObject = (obj) => {
    onAddObject(obj); // Call the parent function to add the selected object
    setSearchQuery(''); // Reset the search query to clear the search bar
  };

  return (
    <div className="mt-4">
      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search Salesforce Objects..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full rounded-md border border-gray-300 px-4 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
      />

      {/* Render search results */}
      {searchResults.length > 0 && (
        <div className="mt-2 border rounded-md text-sm text-left bg-white px-4 max-h-48 overflow-y-auto">
          {/* Limit height and make scrollable */}
          <ul>
            {searchResults.map((obj) => (
              <li
                key={obj.name}
                className="cursor-pointer p-2 hover:bg-indigo-100 rounded-md"
                onClick={() => handleSelectObject(obj)}
              >
                {obj.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchObjects;