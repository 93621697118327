// src/slices/runningSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  runningRuleIds: [], // Array to store multiple running rule IDs
};

const runningSlice = createSlice({
  name: 'running',
  initialState,
  reducers: {
    addRunningRule(state, action) {
      // Only add the ruleId if it's not already in the array
      if (!state.runningRuleIds.includes(action.payload)) {
        state.runningRuleIds.push(action.payload);
      }
    },
    removeRunningRule(state, action) {
      // Remove the ruleId from the array
      state.runningRuleIds = state.runningRuleIds.filter(
        (ruleId) => ruleId !== action.payload
      );
    },
    clearAllRunningRules(state) {
      // Clear all running rules
      state.runningRuleIds = [];
    },
  },
});

export const { addRunningRule, removeRunningRule, clearAllRunningRules } = runningSlice.actions;

export default runningSlice.reducer;