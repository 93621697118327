import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'; // Use updated components
import {
  ArrowPathIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  ArrowRightStartOnRectangleIcon,
  UserCircleIcon,
  CreditCardIcon, // Import the CreditCardIcon
} from '@heroicons/react/20/solid';
import { addNotification } from '../../slices/notificationSlice';
import { fetchSalesforceObjects } from '../../slices/salesforceObjectsSlice';
import { fetchOrgCredits } from '../../slices/orgCreditsSlice'; // Import fetchOrgCredits thunk
import axiosInstance from '../../axiosInstance';

const UserMenu = () => {
  const dispatch = useDispatch();

  // Fetch the organization credits when the component mounts
  useEffect(() => {
    dispatch(fetchOrgCredits());
  }, [dispatch]);

  // Get the organization credits and loading status from Redux state
  const { credits, status } = useSelector((state) => state.orgCredits);

  const handleSalesforceConnection = async () => {
    dispatch(addNotification({ message: 'Requesting updated Salesforce Configuration', description: '', type: 'info' }));
    try {
      const response = await axiosInstance.get('/api/salesforce-config/true'); // Use 'true' as the parameter
      await dispatch(fetchSalesforceObjects()).unwrap(); // Wait for fetchSalesforceObjects to complete
      dispatch(addNotification({ message: 'Salesforce Configuration successfully updated', description: '', type: 'success' }));
    } catch (error) {
      console.error('Error fetching Salesforce configuration:', error);
      dispatch(addNotification({ message: 'Error updating Salesforce Configuration', description: error.message, type: 'error' }));
    }
  };

  const handleLogout = async () => {
    dispatch(addNotification({ message: 'Logging out...', description: '', type: 'info' }));
    try {
      await axiosInstance.post('/auth/logout'); // Base URL is already set in axiosInstance
      dispatch(addNotification({ message: 'Successfully logged out', description: '', type: 'success' }));
      window.location.href = '/'; // Redirect to the homepage or login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
      dispatch(addNotification({ message: 'Error during logout', description: error.message, type: 'error' }));
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="inline-flex items-center justify-center rounded-full">
        <UserCircleIcon className="h-8 w-8 text-gray-600" aria-hidden="true" />
      </MenuButton>
      <MenuItems className="absolute bottom-full mb-2 z-10 w-56 origin-bottom-right translate-y-0 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {/* Replace this "Test" MenuItem with org credits */}
          <MenuItem>
  <div className="group flex items-center px-4 py-2 text-sm text-gray-700">
    <CreditCardIcon
      aria-hidden="true"
      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
    />
    {/* Display loading text or credits rounded to two decimal places */}
    {status === 'loading' 
      ? 'Loading credits...' 
      : `Credits: ${(credits || 0).toFixed(1)}`}
  </div>
</MenuItem>
        </div>
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              onClick={handleSalesforceConnection}
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Salesforce Refresh
            </a>
          </MenuItem>
        </div>
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              onClick={handleLogout}
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <ArrowRightStartOnRectangleIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Log Out
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
};

export default UserMenu;