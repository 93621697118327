import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import HeroIcon from '../HeroIcon.jsx'; // Import the updated HeroIcon component

const SalesforceObject = ({ salesforceObjects, onSelectObject, selectedObject }) => {
  const [isListVisible, setIsListVisible] = useState(true);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  return (
    <div className="">
      <div className="mt-6">
        <button
          onClick={toggleListVisibility}
          className="flex items-center w-full text-left text-gray-900 rounded hover:text-[#8A79EC]"
        >
          <span className="ml-3 font-bold">Salesforce Objects</span>
          {isListVisible ? (
            <ChevronUpIcon className="h-3 w-3 inline-block ml-2" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-3 w-3 inline-block ml-2" aria-hidden="true" />
          )}
        </button>
      </div>
      {isListVisible && (
        <ul className="p-2 pl-4">
          {salesforceObjects.map((object) => {
            const isSelected = selectedObject && selectedObject._id === object._id;
            return (
              <li key={object._id}>
                <button
                  onClick={() => onSelectObject(object)}
                  className={`block w-full text-left text-gray-900 text-sm p-2 ${isSelected ? 'font-semibold text-black' : 'hover:text-[#8A79EC]'}`}
                >
                  {/* Use HeroIcon component to dynamically render the icon */}
                  <HeroIcon
                    iconName={object.heroIcon} // Pass the icon name from the Salesforce object
                    type={isSelected ? 'solid' : 'outline'} // Use solid type for selected, outline otherwise
                    className={`h-6 w-6 inline-block mr-2 mb-1 ${isSelected ? 'text-[#8A79EC]' : ''}`} // Adjust the style based on selection
                  />
                  {object.label}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SalesforceObject;