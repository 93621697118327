import React, { useState, useEffect } from 'react';
import { Dialog, Transition, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCreateFixOpen } from '../../slices/uiSlice.js';
import { setSelectedRuleId } from '../../slices/selectedRuleIdSlice.js';
import { lineWobble, ring } from 'ldrs';
import SalesforceObjectIcon from '../rules/SalesforceObjectIcon.jsx';
import DndWrapper from './CreateFixPreview.jsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { updateRuleDirectly } from '../../slices/rulesSlice.js';
import axiosInstance from '../../axiosInstance.js';
import CreateFixFeedback from './CreateFixFeedback';
import AvailableFields from '../rules/AvailableFields.jsx'; // Import the AvailableFields component
import ValidateFix from './ValidateFix.jsx';

const CreateFix = ({ selectedSalesforceObject }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isCreateFixOpen);
  const selectedRuleId = useSelector((state) => state.selectedRuleId);
  const currentRules = useSelector((state) => state.rules.data);
  const selectedRule = currentRules.find((rule) => rule._id === selectedRuleId);

  const [description, setDescription] = useState(''); // Prompt description for the fix
  const [isGenerating, setIsGenerating] = useState(false); // Loading state for generating fix
  const [isGenerated, setIsGenerated] = useState(false); // Whether a fix has been generated
  const [previewFix, setPreviewFix] = useState(null); // Preview of the fix
  const [isEditable, setIsEditable] = useState(true); // Control whether textarea is editable
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false); // Visibility of feedback form
  const [fixPrompts, setFixPrompts] = useState([]); // State to store fix prompt recommendations
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0); // For cycling through prompts
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const [isFixValid, setIsFixValid] = useState(false);


  lineWobble.register();
  ring.register();

  useEffect(() => {
    if (!isOpen) {
      setDescription('');
      setIsGenerating(false);
      setIsGenerated(false);
      setPreviewFix(null);
      setIsEditable(true);
      setIsFeedbackVisible(false);
      setFixPrompts([]); // Reset prompts
      setIsFixValid(false);
      setCurrentPromptIndex(0);
    }

    if (isOpen) {
      // Set loading state to true while fetching prompts
      setIsLoadingPrompts(true);

      if (selectedRule && selectedRule.fixPromptRecs && selectedRule.fixPromptRecs.length > 0) {
        setFixPrompts(selectedRule.fixPromptRecs);
        setIsLoadingPrompts(false);
      } else if (selectedRule) {
        axiosInstance.get(`/api/get-fix-prompt-recs/${selectedRule._id}`)
          .then(response => {
            setFixPrompts(response.data);
            setIsLoadingPrompts(false);

            const updatedRule = {
              ...selectedRule,
              fixPromptRecs: response.data // Add the new fixPromptRecs to the rule
            };
            dispatch(updateRuleDirectly(updatedRule)); // Update Redux state
          })
          .catch(error => {
            console.error('Error fetching fix prompt recommendations:', error);
            setIsLoadingPrompts(false); // Stop loading even if there's an error
          });
      }
    }

    if (selectedRule && selectedRule.fix) {
      setDescription(selectedRule.fixPrompt || '');
      setPreviewFix(selectedRule.fix || null);
      setIsGenerated(true);
      setIsEditable(false);
    } else {
      setDescription('');
      setPreviewFix(null);
      setIsGenerated(false);
      setIsEditable(true);
    }
  }, [isOpen, selectedRule]);

  useEffect(() => {
    if (fixPrompts.length > 0) {
      const interval = setInterval(() => {
        setCurrentPromptIndex((prevIndex) => (prevIndex + 1) % fixPrompts.length);
      }, 4000); // Change prompt every 4 seconds
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [fixPrompts]);

  // Function to insert field into the textarea at cursor position
  const insertFieldAtCursor = (fieldName) => {
    const textarea = document.getElementById('description');
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;

    const updatedText = `${text.substring(0, start)}${fieldName}${text.substring(end)}`;
    setDescription(updatedText);

    // Move the cursor to the end of the inserted text
    setTimeout(() => {
      textarea.setSelectionRange(start + fieldName.length, start + fieldName.length);
      textarea.focus();
    }, 0);
  };

  const onSave = async (e) => {
    e.preventDefault();

    try {
      const saveResponse = await axiosInstance.post('/api/save-fix', {
        ruleId: selectedRule._id,
        fix: previewFix,
        prompt: description,
      });

      const updatedRule = saveResponse.data;
      dispatch(updateRuleDirectly(updatedRule)); // Update Redux state with the saved fix
      dispatch(setSelectedRuleId(updatedRule._id)); // Ensure the selected rule is updated
      dispatch(setIsCreateFixOpen(false)); // Close modal
    } catch (error) {
      console.error('Error saving fix:', error);
    }
  };

  const onGenerateFix = async () => {
    setIsGenerating(true);
    setIsEditable(false);

    try {
      const response = await axiosInstance.post('/api/generate-fix', {
        rule: selectedRule,
        fix: description, // Send the current description as the fix prompt
      });

      setPreviewFix(response.data.generatedFix); // Update the fix preview with the new response
      setIsGenerated(true); // Mark as generated
    } catch (error) {
      console.error('Error generating fix:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const onEditPrompt = () => {
    setIsEditable(true);
    setIsGenerated(false); // Reset the generated state so new generation can occur
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog open={isOpen} onClose={() => dispatch(setIsCreateFixOpen(false))} className="relative z-10">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => dispatch(setIsCreateFixOpen(false))}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="text-center">
                  <div className="mx-auto flex h-12 w-12 items-center justify-center">
                    {selectedSalesforceObject && selectedSalesforceObject.name ? (
                      <SalesforceObjectIcon objectName={selectedSalesforceObject.name} />
                    ) : (
                      <p className="text-gray-500">No object selected</p>
                    )}
                  </div>
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {selectedRule && selectedRule.fix ? 'Editing Fix' : 'Creating a new fix'} for the {selectedRule ? selectedRule.ruleName : 'unknown'} rule
                  </DialogTitle>
                  <p className="mt-2 text-sm text-gray-500">How do you want to fix these issues?</p>
                </div>

                <form className="mt-6" onSubmit={onSave}>
                  <div className="space-y-6">
                    {/* Prompt textarea */}
                    <div className="mt-2 relative">
                      <textarea
                        id="description"
                        name="description"
                        rows={4}
                        className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${isGenerating || !isEditable ? 'bg-gray-200' : ''}`}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={!isEditable}
                      />


                      {/* Loading state for when prompts are being fetched */}
                      {isLoadingPrompts && (
                        <div className="flex justify-center mt-4">
                          <p className="text-sm text-gray-500 mr-2">Generating suggested prompts...</p>
                          <l-ring
                            size="20"
                            stroke="5"
                            bg-opacity="0"
                            speed="2"
                            color="#8A79EC"
                          ></l-ring>
                        </div>
                      )}

                      {/* Suggested prompts, only displayed when there are fixPrompts */}
                      {!isLoadingPrompts && fixPrompts.length > 0 && !isGenerated && !description && (
                        <div
                          className="mt-4 ml-2 text-sm font-semibold text-indigo-600 min-h-[3rem] max-h-[5rem] overflow-y-auto"
                          onClick={() => setDescription(fixPrompts[currentPromptIndex]?.prompt)}
                          onMouseEnter={(e) => (e.currentTarget.style.color = '#3730a3')}
                          onMouseLeave={(e) => (e.currentTarget.style.color = '#5a67d8')}
                          style={{ cursor: 'pointer' }}
                        >
                          <strong className="text-gray-600">Suggested Prompt:</strong> {fixPrompts[currentPromptIndex]?.prompt}
                        </div>
                      )}

                      {isGenerating && (
                        <div className="mt-4 flex justify-center">
                          <l-line-wobble
                            size="300"
                            stroke="5"
                            bg-opacity="0.1"
                            speed="1.75"
                            color="#8A79EC"
                          ></l-line-wobble>
                        </div>
                      )}
                      {isGenerated && (
                        <button
                          type="button"
                          className="absolute right-2 bottom-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={onEditPrompt}
                        >
                          Edit Prompt
                        </button>
                      )}
                    </div>

                    {/* Available Fields Component */}
                    <AvailableFields
                      onInsertField={insertFieldAtCursor}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                    />

                    <ValidateFix previewFix={previewFix} onValidationStatusChange={setIsFixValid} />

                    {/* Fix preview */}
                    {previewFix && (
                      <div className="mt-4 p-4 bg-[#f9f9f9] border border-gray-200 rounded-lg">
                        <DndProvider backend={HTML5Backend}>
                          <DndWrapper previewFix={previewFix} setPreviewFix={setPreviewFix} />
                        </DndProvider>
                        <button
                          type="button"
                          className="mt-5 text-xs text-indigo-600 hover:underline"
                          onClick={() => setIsFeedbackVisible(true)}
                        >
                          Not what you expected?
                        </button>
                        {isFeedbackVisible && (
                          <CreateFixFeedback
                            previewFix={previewFix}
                            prompt={description}
                            ruleId={selectedRule._id} // Pass description as prompt
                            onClose={() => setIsFeedbackVisible(false)}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  {/* Form buttons */}
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => dispatch(setIsCreateFixOpen(false))}
                      disabled={isGenerating}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ${isGenerating || !isEditable ? 'cursor-not-allowed bg-gray-400' : 'bg-indigo-600 text-white hover:bg-indigo-500'} sm:col-start-2 sm:mt-0`}
                      onClick={onGenerateFix}
                      disabled={isGenerating || !isEditable}
                    >
                      Generate Fix
                    </button>
                    <button
                      type="submit"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:col-start-3 ${!isGenerated || isFeedbackVisible || !isFixValid
                          ? 'cursor-not-allowed bg-gray-400 text-gray-500'
                          : 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        }`}
                      disabled={!isGenerated || isFeedbackVisible || !isFixValid} // Ensure this line includes isFixValid
                    >
                      Save
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateFix;