import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGetContactEmailOpen } from '../slices/uiSlice.js';
import axiosInstance from '../axiosInstance.js'; // Import axiosInstance for API calls
import { fetchOrganization } from '../slices/organizationSlice.js';
import { fetchUser } from '../slices/userSlice.js';

const GetContactEmail = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isGetContactEmailOpen);
  const user = useSelector((state) => state.user.data); // User data, including email
  const organization = useSelector((state) => state.organization.data); // Organization data, including name

  const [companyName, setCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false); // Track save button state
  const [isEmailValid, setIsEmailValid] = useState(true); // Track email validation state

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Determine if the organization name is set to "placeholder"
  const isPlaceholderOrg = organization?.name === 'placeholder';

  // Set initial values for company name if the organization name is not "placeholder"
  useEffect(() => {
    if (organization?.name && !isPlaceholderOrg) {
      setCompanyName(organization.name);
    }
  }, [organization, isPlaceholderOrg]);

  const handleEmailChange = (email) => {
    setContactEmail(email);
    setIsEmailValid(emailRegex.test(email)); // Validate email on change
  };

  const handleSave = async () => {
    if (companyName && contactEmail && isEmailValid) {
      setIsSaving(true); // Show loading indicator or disable button
  
      try {
        // Send POST request to save the organization name and contact email
        await axiosInstance.post('/api/add-org-name-and-contact-email', {
          orgName: companyName,
          contactEmail: contactEmail,
        });
  
        console.log('Organization and contact email saved successfully.');
  
        // Wait for both fetchUser and fetchOrganization to complete
        await Promise.all([
          dispatch(fetchUser()).unwrap(), // Waits for fetchUser to complete
          dispatch(fetchOrganization()).unwrap(), // Waits for fetchOrganization to complete
        ]);
  
        // Now that both fetchUser and fetchOrganization have completed successfully, close the modal
        dispatch(setIsGetContactEmailOpen(false));
      } catch (error) {
        console.error('Error saving organization and contact email:', error);
      } finally {
        setIsSaving(false); // Re-enable button or hide loading indicator
      }
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog open={isOpen} onClose={() => {}} className="relative z-10">
        {/* Background overlay */}
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Centered modal */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {/* Modal content */}
                <div>
                  <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
                    A few more details...
                  </Dialog.Title>

                  {/* Company Name Section */}
                  <div className="mt-6">
                    <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                      Company Name
                    </label>
                    <input
                      id="companyName"
                      name="companyName"
                      type="text"
                      className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      disabled={!isPlaceholderOrg} // Disable input if organization name is not "placeholder"
                    />
                  </div>

                  {/* Contact Email Section */}
                  <div className="mt-8">
                    <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">
                      Work Email
                    </label>
                    <div className="flex items-center">
                      <input
                        id="contactEmail"
                        name="contactEmail"
                        type="email"
                        className={`block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                          !isEmailValid ? 'border-red-500 text-red-600' : ''
                        }`}
                        value={contactEmail}
                        onChange={(e) => handleEmailChange(e.target.value)}
                      />
                    </div>
                    {!isEmailValid && (
                      <p className="text-sm mt-2 text-red-600">
                        Please enter a valid email address.
                      </p>
                    )}
                    <div>
                      <button
                        type="button"
                        className="ml-2 px-2 py-1 mt-4 bg-indigo-600 text-white text-xsm rounded-md shadow-sm hover:bg-indigo-500"
                        onClick={() => handleEmailChange(user?.email)} // Use user's email for contact
                      >
                        use '{user?.email}'
                      </button>
                    </div>
                    <p className="text-sm mt-12 text-center text-gray-800">
                      tarnData needs your work email for important notifications
                    </p>
                  </div>
                </div>

                {/* Save Button */}
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm ${
                      companyName && contactEmail && isEmailValid ? '' : 'cursor-not-allowed opacity-50'
                    }`}
                    onClick={handleSave}
                    disabled={isSaving || !companyName || !contactEmail || !isEmailValid} // Disable if any field is empty or saving or invalid email
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GetContactEmail;