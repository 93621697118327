import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsSettingsActive } from '../../slices/uiSlice.js';
import AccountSettings from './AccountSettings';
import NotificationsSettings from './NotificationsSettings';
import OrganizationSettings from './OrganizationSettings.jsx';

const navigation = [
  { name: 'Account', component: 'account' },
  { name: 'Organization', component: 'organization' },
  { name: 'Notifications', component: 'notifications' },
 // New tab
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Settings() {
  const isSettingsActive = useSelector((state) => state.ui.isSettingsActive);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('account');

  if (!isSettingsActive) return null;

  const renderContent = () => {
    switch (activeTab) {
      case 'account':
        return <AccountSettings />;
      case 'notifications':
        return <NotificationsSettings />;
      case 'organization':
        return <OrganizationSettings />; // Render TeamSettings on 'Team' tab
      default:
        return <AccountSettings />;
    }
  };

  return (
    <div className="p-6 w-full h-[80vh] overflow-y-auto ml-6 mb-16">
      {/* Top Navigation Bar */}
      <header className="border-b border-gray-200">
        <nav className="flex overflow-x-auto py-4">
          <ul className="flex gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-700">
            {navigation.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => setActiveTab(item.component)}
                  className={classNames(
                    activeTab === item.component ? 'text-indigo-500' : '',
                    'focus:outline-none'
                  )}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Render Active Tab Content */}
      <div className="mt-10">
        {renderContent()}
      </div>

      <div className="mt-6 flex justify-end">
      </div>
    </div>
  );
}