'use client'

import React, { useState, useEffect, useRef } from 'react';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { 
  BanknotesIcon, 
  BuildingOffice2Icon, 
  UserCircleIcon, 
  RocketLaunchIcon, 
  DocumentMagnifyingGlassIcon, 
  ShoppingCartIcon, 
  DocumentTextIcon, 
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';

const getObjectIcon = (objectName) => {
  const baseClass = "h-6 w-6 inline-block mr-2";
  const hoverClass = "group-hover:text-[#8A79EC]";

  switch (objectName) {
    case 'Opportunity':
      return <BanknotesIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Account':
      return <BuildingOffice2Icon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Contact':
      return <UserCircleIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Lead':
      return <UserCircleIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Campaign':
      return <RocketLaunchIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Case':
      return <DocumentMagnifyingGlassIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Product2':
      return <ShoppingCartIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    case 'Contract':
      return <DocumentTextIcon className={`${baseClass} ${hoverClass}`} aria-hidden="true" />;
    default:
      return null;
  }
};

export default function SearchRules() {
  const [query, setQuery] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchRef = useRef(null);
  
  // Get rules from Redux store
  const rules = useSelector((state) => state.rules.data);

  const filteredItems =
    query === ''
      ? []
      : rules.filter((rule) => {
          return rule.ruleName.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div className="relative w-3/4 max-w-xl" ref={searchRef}>
      <div className="relative border border-gray-300 rounded-lg shadow-sm">
        <MagnifyingGlassIcon
          className="pointer-events-none absolute left-4 top-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <Combobox
          onChange={(item) => {
            if (item) {
              window.location = `/rules/${item._id}`;
            }
          }}
        >
          <ComboboxInput
            autoFocus
            className="h-11 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search Rules"
            onChange={(event) => {
              setQuery(event.target.value);
              setIsDropdownOpen(true);
            }}
            autoComplete="off"
          />
          {isDropdownOpen && query !== '' && (
            <ComboboxOptions static className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white bg-opacity-98 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {filteredItems.map((item) => (
                <ComboboxOption
                  key={item._id}
                  value={item}
                  className="group flex cursor-default select-none rounded-xl p-3 data-[focus]:bg-gray-100"
                >
                  <div className="flex items-center">
                    {getObjectIcon(item.salesforceObject)}
                    <div className="ml-4 flex-auto">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-[#8A79EC] group-data-[focus]:text-gray-900">{item.ruleName}</p>
                    </div>
                  </div>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </Combobox>
      </div>
      {isDropdownOpen && query !== '' && filteredItems.length === 0 && (
        <div className="absolute mt-1 w-full rounded-md bg-white bg-opacity-98 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
          <div className="px-6 py-14 text-center text-sm sm:px-14">
            <p className="mt-4 font-semibold text-gray-900">No results found</p>
            <p className="mt-2 text-gray-500">No rules found for this search term. Please try again.</p>
          </div>
        </div>
      )}
    </div>
  );
}