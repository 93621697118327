import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { CheckIcon, BoltIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

const lightningTier = {
    name: 'Lightning',
    id: 'tier-lightning',
    price: '$225',
    description: "Never see an empty Salesforce field again! Get 75 bonus credits on us and prioritized OpenAI calls.",
    features: [
        '225 credits + 75 bonus credits',
        'Access to All Features',
        'Higher Priority OpenAI Calls',
        'Unlimited Users, Rules, & Fixes',
        '~ 200,000 to 1,300,000 Salesforce record updates',
        'Dedicated database',
        'Full audit trail available',
    ],
    mostPopular: true,
};

const BuyLightning = () => {
    // State to manage loading status
    const [loading, setLoading] = useState(false);

    // Function to handle buying credits for the lightning package
    const buyCredits = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axiosInstance.post(`/api/buy-credits/lightning`);
            const { paymentLink } = response.data;
            window.location.href = paymentLink; // Redirect to Stripe checkout page
        } catch (error) {
            console.error('Error buying credits:', error);
            alert('Failed to initiate purchase. Please try again.');
        } finally {
            setLoading(false); // Stop loading after response or error
        }
    };

    return (
        <div className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 w-full max-w-md mx-auto">
            <div>
                <div className="flex items-center justify-between gap-x-4">
                    <h3 id={lightningTier.id} className="text-lg font-semibold text-indigo-600 flex items-center gap-x-2">
                        {lightningTier.name}
                        <BoltIcon className="h-5 w-5 text-indigo-600" />
                    </h3>
                    {lightningTier.mostPopular && (
                        <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold text-indigo-600">
                            Most popular
                        </p>
                    )}
                </div>
                <p className="mt-4 text-sm text-gray-600">{lightningTier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-semibold tracking-tight text-gray-900">
                        {lightningTier.price}
                    </span>
                </p>
                <ul role="list" className="mt-8 space-y-3 text-sm text-gray-600">
                    {lightningTier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3 items-center">
                            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                            {feature}
                            {feature.includes('Salesforce record updates') && (
                                <div className="relative group">
                                    <InformationCircleIcon className="h-4 w-4 text-indigo-400 ml-1" />
                                    <div className="absolute bottom-full mb-2 hidden w-64 p-2 text-xs text-gray-700 bg-white border border-gray-200 rounded shadow-lg z-50 group-hover:block">
                                        Approximation dependent on OpenAI usage. Salesforce API call limits apply. API calls are made efficiently by batching records in each call.
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <button
                onClick={buyCredits}
                disabled={loading} // Disable the button when loading
                className={`mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold 
                            ${loading ? 'bg-gray-400 text-gray-200' : 'bg-indigo-600 text-white hover:bg-indigo-500'}
                            shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
                {loading ? 'Processing...' : 'Buy Credits'}
            </button>
            {loading && (
                <div className="flex justify-center items-center mt-4">
                    <l-line-wobble
                        size="200"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.75"
                        color="#8A79EC"
                    ></l-line-wobble>
                </div>
            )}
        </div>
    );
};

export default BuyLightning;