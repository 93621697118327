import React, { forwardRef } from 'react';
import moment from 'moment'; // Optional: use moment.js for formatted timestamps
import SalesforceObjectIcon from '../rules/SalesforceObjectIcon';
import { PlayIcon } from '@heroicons/react/24/outline';

const RunEvent = forwardRef(({ event, onComplete }, ref) => {
  const ruleName = event.ruleName || 'Unknown Rule';
  const issueCount =
    event.issueCount !== undefined ? event.issueCount : 'an unknown number of';
  const fullTextAfterCount = ` issues`;

  return (
    <div
      ref={ref}
      className="p-2 bg-[#f9f9f9] border rounded-lg mb-2 flex items-center justify-between"
    >
      {/* Icons div */}
      <div className="flex items-center mr-2 flex-shrink-0">
        <PlayIcon className="h-4 w-4 text-indigo-600 mr-1" aria-hidden="true" />
        <SalesforceObjectIcon objectName={event.salesforceObject} />
      </div>

      {/* Rule text div */}
      <div className="flex-grow mx-2">
        <span className="font-bold">Rule:</span> {' '}
        <span className="font-semibold text-indigo-600">{ruleName}</span> rule was run and found
        <div className="inline-block p-1 pl-2 ml-1 rounded border">
          <span className="font-mono font-semibold text-gray-900">
            {issueCount}
          </span>
          <span className="font-mono font-semibold text-red-700 pr-1">
            {fullTextAfterCount}
          </span>
        </div>
      </div>

      {/* Timestamp div */}
      <div className="text-right ml-2 flex-shrink-0">
        <p className="font-semibold text-gray-900 text-center">
          {moment(event.timestamp).format('h:mm:ss a')}
        </p>
        <p className="text-gray-600">
          {moment(event.timestamp).format('MMMM Do YYYY')}
        </p>
      </div>
    </div>
  );
});

export default RunEvent;