import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../axiosInstance';
import RunDetails from './RunDetails';
import { ArrowPathIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import downloadDetails from './downloadDetails';
import AuditTableHeader from './AuditTableHeader';
import DeleteRuns from './DeleteRuns';

const Audit = () => {
  const isAuditActive = useSelector((state) => state.ui.isAuditActive);
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRunId, setExpandedRunId] = useState(null);
  const [completedRunCount, setCompletedRunCount] = useState(0);
  const [auditCost, setAuditCost] = useState(0);
  const [selectedRuns, setSelectedRuns] = useState([]); // Track selected runs
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Track "Select All" state

  // Handle "Select All" toggle
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRuns([]); // Deselect all
    } else {
      setSelectedRuns(runs.map((run) => run._id)); // Select all run IDs
    }
    setIsAllSelected(!isAllSelected);
  };

  // Update `isAllSelected` based on `selectedRuns` and total `runs`
  useEffect(() => {
    setIsAllSelected(selectedRuns.length === runs.length && runs.length > 0);
  }, [selectedRuns, runs]);

  const fetchRuns = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/get-last-50-runs');
      let runsData = response.data;

      const incompleteRuns = runsData.filter(
        (run) => run.status !== 'complete' && run.status !== 'failed'
      );
      const completedRuns = runsData
        .filter((run) => run.status === 'complete' || run.status === 'failed')
        .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt));

      setRuns([...incompleteRuns, ...completedRuns].map((run) => ({ ...run, details: null })));
    } catch (error) {
      console.error('Error fetching runs:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAuditCost = async () => {
    try {
      const response = await axiosInstance.get('/api/get-audit-cost');
      const count = response.data.count;
      setCompletedRunCount(count);
      setAuditCost((count * 0.0004).toFixed(4));
    } catch (error) {
      console.error('Error fetching audit cost:', error);
    }
  };

  useEffect(() => {
    if (isAuditActive) {
      fetchRuns();
      getAuditCost();
    }
  }, [isAuditActive]);

  if (!isAuditActive) return null;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const day = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    return { time, day };
  };

  const handleRunSelection = (runId) => {
    setSelectedRuns((prevSelectedRuns) =>
      prevSelectedRuns.includes(runId) ? prevSelectedRuns.filter((id) => id !== runId) : [...prevSelectedRuns, runId]
    );
  };

  const handleDeleteRuns = async (runIds) => {
    try {
      await axiosInstance.post('/api/delete-runs', { runIds });
      setRuns((prevRuns) => prevRuns.filter((run) => !runIds.includes(run._id)));
      setSelectedRuns([]);
    } catch (error) {
      console.error('Error deleting runs:', error);
    }
  };

  const handleDownload = async (runId) => {
    const run = runs.find((r) => r._id === runId);
    if (run.details) {
      downloadDetails(runId, run.details);
    } else {
      try {
        const response = await axiosInstance.get(`/api/get-run-details/${runId}`);
        setRuns((prev) => prev.map((r) => (r._id === runId ? { ...r, details: response.data } : r)));
        downloadDetails(runId, response.data);
      } catch (error) {
        console.error('Error fetching run details for download:', error);
      }
    }
  };

  const toggleDetails = async (runId) => {
    const run = runs.find((r) => r._id === runId);
    if (run.details) {
      setExpandedRunId((prevRunId) => (prevRunId === runId ? null : runId));
    } else {
      try {
        const response = await axiosInstance.get(`/api/get-run-details/${runId}`);
        setRuns((prev) => prev.map((r) => (r._id === runId ? { ...r, details: response.data } : r)));
        setExpandedRunId(runId);
      } catch (error) {
        console.error('Error fetching run details:', error);
      }
    }
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'complete':
        return 'bg-green-50 text-green-700 ring-green-600/20';
      case 'failed':
        return 'bg-red-50 text-red-700 ring-red-600/20';
      default:
        return 'bg-indigo-50 text-indigo-700 ring-indigo-600/20';
    }
  };

  return (
    <div className="w-full h-[calc(100vh-140px)] mt-2 mb-16 mr-4 overflow-hidden">
      <div className="sticky top-0 z-10 py-2 flex justify-between items-center bg-white">
        <h1 className="text-2xl font-bold mt-8 ml-6">
          Audit
          <span className="text-sm font-normal text-gray-500 ml-2">
            (hosting <strong>{completedRunCount}</strong> completed runs for <strong>{auditCost}</strong> credits / day)
          </span>
        </h1>

        <div className="flex items-center space-x-4 mr-8 mt-8">
          <button
            onClick={() => {
              fetchRuns();
              getAuditCost();
            }}
            className="rounded-md bg-indigo-600 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            <ArrowPathIcon className="h-4 w-4 inline-block mr-2" aria-hidden="true" />
            Refresh
          </button>

          {selectedRuns.length > 0 && (
            <button
              onClick={() => setIsDeleteModalOpen(true)} // Open DeleteRuns modal
              className="rounded-md bg-red-600 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500"
            >
              Delete {selectedRuns.length} Runs
            </button>
          )}
        </div>
      </div>

      {isDeleteModalOpen && (
        <DeleteRuns
          selectedRuns={selectedRuns}
          onConfirmDelete={handleDeleteRuns}
          onClose={() => setIsDeleteModalOpen(false)} // Close modal on cancel or after deletion
        />
      )}

      {loading ? (
        <p className="text-center mt-4 text-gray-500">Loading runs...</p>
      ) : (
        <div className="border border-gray-200 rounded-lg px-1 h-[calc(100vh-240px)] mt-4 pr-6 w-full overflow-auto">
          <table className="w-full table-fixed">
            <AuditTableHeader isAllSelected={isAllSelected} onSelectAll={handleSelectAll} />
            <tbody className="bg-white text-xsm">
              {runs.map((run) => {
                const { time, day } = formatDate(run.completedAt) || {};
                const statusStyles = getStatusStyles(run.status);

                return (
                  <React.Fragment key={run._id}>
                    <tr>
                      <td className="border-b border-gray-200 whitespace-nowrap overflow-hidden text-ellipsis py-3 pl-2 pr-2 text-gray-900">
                        {run.ruleName || 'N/A'}
                      </td>
                      <td className="border-b border-gray-200 hidden lg:table-cell whitespace-nowrap overflow-hidden text-ellipsis px-2 py-3 text-gray-500">
                        {run.type || 'N/A'}
                      </td>
                      <td className="border-b border-gray-200 hidden lg:table-cell whitespace-nowrap overflow-hidden text-ellipsis px-2 py-3">
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusStyles}`}
                        >
                          {run.status || 'N/A'}
                        </span>
                      </td>
                      <td className="border-b border-gray-200 whitespace-nowrap overflow-hidden text-ellipsis px-2 py-3 text-gray-500">
                        {run.issuesResolved || 0}/{run.issuesTotal || 0}
                      </td>
                      <td className="border-b border-gray-200 whitespace-nowrap overflow-hidden text-ellipsis px-2 py-3 text-gray-500">
                        {run.creditAmount != null && run.creditAmount !== 0 ? run.creditAmount.toFixed(3) : ''}
                      </td>
                      <td className="border-b border-gray-200 whitespace-nowrap overflow-hidden text-ellipsis px-2 py-3 text-gray-500">
                        <div>{time}</div>
                        <div>{day}</div>
                      </td>
                      <td className="border-b border-gray-200 relative whitespace-nowrap py-3 pl-2 pr-2 text-right font-medium">
                        {run.status === 'complete' && (
                          <>
                            <button
                              className="text-indigo-600 font-bold inline-flex items-center mr-3"
                              onClick={() => toggleDetails(run._id)}
                            >
                              {expandedRunId === run._id ? 'Details' : 'Details'}
                            </button>
                            <button
                              className="text-indigo-600 inline-flex items-center mr-3 mt-2"
                              onClick={() => handleDownload(run._id)}
                            >
                              <ArrowDownTrayIcon className="h-5 w-5 text-indigo-600" />
                            </button>
                          </>
                        )}
                      </td>
                      <td className="border-b border-gray-200 py-3 pl-2 pr-2 text-center">
                        <input
                          type="checkbox"
                          checked={selectedRuns.includes(run._id)}
                          onChange={() => handleRunSelection(run._id)}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        />
                      </td>
                    </tr>
                    {expandedRunId === run._id && run.details && (
                      <tr>
                        <td colSpan="7" className="bg-gray-50">
                          <RunDetails
                            runId={run._id}
                            details={run.details}
                            onClose={() => setExpandedRunId(null)}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Audit;