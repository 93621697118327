import React from 'react';
import SalesforceObject from './SalesforceObject.jsx';
import Admin from './Admin.jsx';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setIsAddSalesforceObjectOpen } from '../../slices/uiSlice.js';

const Navigation = ({ salesforceObjects, onSelectObject, selectedObject }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col w-1/8 shrink-0 h-[calc(100vh-96px)] pb-10 rounded-lg overflow-auto">
      <div className="flex-1 overflow-auto">
        {/* SalesforceObject Component */}
        <SalesforceObject
          salesforceObjects={salesforceObjects}
          onSelectObject={onSelectObject}
          selectedObject={selectedObject}
        />

        {/* DashedBox Styled Button */}
        <div className="px-16"> {/* Padding around the DashedBox */}
          <button
            onClick={() => dispatch(setIsAddSalesforceObjectOpen(true))} // Open AddSalesforceObject modal
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-1 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
<PlusIcon className="mx-auto h-5 w-5 text-indigo-500" aria-hidden="true" strokeWidth={2} /> {/* PlusIcon */}
          </button>
        </div>

        {/* Admin Component */}
        <Admin />
      </div>
    </div>
  );
};

export default Navigation;