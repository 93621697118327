import React, { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'; // Importing icons for expanding/collapsing

const IssueFixPreview = ({ evaluatedCondition }) => {
    const [isExpanded, setIsExpanded] = useState(false); // State to handle expanded/collapsed section

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="w-full p-2 mt-5 text-xs bg-gray-100 rounded-lg">
            <div className="flex items-center">
                {/* Triangle toggle button, now to the left of "Fix" */}
                <button onClick={toggleExpand} className="flex items-center">
                    {isExpanded ? <ChevronDownIcon className="h-4 w-4 text-indigo-600" /> : <ChevronRightIcon className="h-4 w-4 text-indigo-600" />}
                </button>

                {/* "Fix" text that can also toggle expansion */}
                <button onClick={toggleExpand} className="ml-1 text-indigo-600 hover:underline">
                    <strong className ="text-sm">Fix:</strong>
                </button>

                <p className="ml-1">
                    <span className="font-mono bg-white rounded-md border p-1 px-2 text-indigo-700">{evaluatedCondition.actions[0].fieldName}</span> to
                    <span className="font-mono bg-white border rounded-md p-1 ml-1 px-2 text-indigo-700">{evaluatedCondition.actions[0].newValue}</span>
                </p>
            </div>

            {/* Show additional actions and conditions when expanded */}
            {isExpanded && (
                <div className="mt-2">
                    {/* Show additional actions if any */}
                    {evaluatedCondition.actions.slice(1).map((action, index) => (
                        <p key={index}>
                            <strong>Action:</strong> {action.fieldName} – {action.newValue}
                        </p>
                    ))}
                    Field Conditions: 
                    <ul className="mt-2 list-disc list-inside">
                        {evaluatedCondition.fieldConditions.map((fieldCondition, index) => (
                            <li key={index}>
                                <strong>{fieldCondition.fieldName}</strong>{' '}
                                {fieldCondition.operator && `${fieldCondition.operator} `}
                                {fieldCondition.conditionValue && `${fieldCondition.conditionValue}`} = {`${fieldCondition.evaluation}`}
                            </li>
                        ))}
                    </ul>

                    {/* Add conditionDescription at the bottom */}
                    <p className="mt-4 text-gray-600 italic">
                        {evaluatedCondition.conditionDescription}
                    </p>
                </div>
            )}
        </div>
    );
};

export default IssueFixPreview;