// CreateSchedule.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Transition,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import {
  XMarkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../slices/notificationSlice.js';
import axiosInstance from '../../../axiosInstance.js';
import CreateRulePreview from '../../rules/CreateRulePreview.jsx';
import CreateFixPreview from '../../fixes/CreateFixPreview.jsx';
import ScheduledRun from './ScheduledRun.jsx';
import ScheduleForm from './ScheduleForm.jsx';
import fetchRuleSchedules from './fetchRuleSchedules.js';
import { fetchRuleById } from '../../../slices/rulesSlice.js';

const CreateSchedule = ({ isOpen, setIsOpen, selectedRule }) => {
  const dispatch = useDispatch();
  const [scheduledRuns, setScheduledRuns] = useState([]);
  const [isAddingRun, setIsAddingRun] = useState(false);
  const [frequency, setFrequency] = useState('hourly');
  const [startDate, setStartDate] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isRuleExpanded, setIsRuleExpanded] = useState(false);
  const [isFixExpanded, setIsFixExpanded] = useState(false);

  const previewFix = selectedRule?.fix;

  useEffect(() => {
    if (isOpen) {
      // Fetch existing schedules when the modal is opened
      const fetchSchedules = async () => {
        try {
          const schedules = await fetchRuleSchedules(selectedRule._id);
          console.log('Fetched and normalized schedules:', schedules);
          setScheduledRuns(schedules);
        } catch (error) {
          console.error('Error fetching schedules:', error);
          dispatch(
            addNotification({
              type: 'error',
              message: 'Error fetching schedules',
              description: 'There was an error fetching the existing schedules.',
            })
          );
        }
      };
      fetchSchedules();
    } else {
      // Reset state when the dialog is closed
      setScheduledRuns([]);
      setIsSaving(false);
      setIsRuleExpanded(false);
      setIsFixExpanded(false);
      setIsAddingRun(false);
      setFrequency('hourly');
      setStartDate('');
    }
  }, [isOpen, selectedRule, dispatch]);

  const toggleRuleExpansion = () => {
    setIsRuleExpanded((prev) => !prev);
    setIsFixExpanded(false);
  };

  const toggleFixExpansion = () => {
    setIsFixExpanded((prev) => !prev);
    setIsRuleExpanded(false);
  };

  const handleAddRun = () => {
    if (startDate) {
      const newScheduledRun = {
        frequency,
        startDate,
        tempId: Date.now(), // Use tempId for new runs
      };
      setScheduledRuns((prevRuns) => [...prevRuns, newScheduledRun]);
      setIsAddingRun(false);
      // Reset form fields
      setFrequency('hourly');
      setStartDate('');
    }
  };

  const handleRemoveRun = (id) => {
    setScheduledRuns((prevRuns) =>
      prevRuns.filter((run) => (run._id || run.tempId) !== id)
    );
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await axiosInstance.post('/api/save-schedule', {
        ruleId: selectedRule._id,
        schedules: scheduledRuns,
      });
      await dispatch(fetchRuleById(selectedRule._id));

      dispatch(
        addNotification({
          type: 'success',
          message: 'Schedule saved successfully',
          description: `Schedules for rule "${selectedRule.ruleName}" have been saved successfully.`,
        })
      );

      setIsOpen(false);
    } catch (error) {
      console.error('Error saving schedule:', error);
      dispatch(
        addNotification({
          type: 'error',
          message: 'Error saving schedule',
          description: 'There was an error saving the schedules.',
        })
      );
    } finally {
      setIsSaving(false);
    }
  };

  const renderScheduledRuns = () => {
    return scheduledRuns.map((run) => (
      <ScheduledRun
        key={run._id || run.tempId}
        frequency={run.frequency}
        startDate={run.startDate}
        onRemove={() => handleRemoveRun(run._id || run.tempId)}
      />
    ));
  };

  const ExpansionIcon =
    isRuleExpanded || isFixExpanded ? ChevronDownIcon : ChevronRightIcon;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-10"
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                {/* Close button */}
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Modal header */}
                <div>
                  <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 flex items-center"
                  >
                    <CalendarDaysIcon className="h-10 w-10 mr-2" aria-hidden="true" />
                  </DialogTitle>
                </div>

                {/* Full Sentence with Independent Dropdowns */}
                <div className="mt-5 flex items-center space-x-2">
                  <p className="text-gray-700">
                    Scheduled runs of{' '}
                    <span
                      className="font-semibold text-indigo-500 hover:text-indigo-700 transition-colors duration-200 cursor-pointer underline"
                      onClick={toggleRuleExpansion}
                    >
                      {selectedRule?.ruleName}
                    </span>{' '}
                    with{' '}
                    <span
                      className="font-semibold text-indigo-500 hover:text-indigo-700 transition-colors duration-200 cursor-pointer underline"
                      onClick={toggleFixExpansion}
                    >
                      this fix:
                    </span>
                  </p>
                </div>

                {/* Rule Name Expansion Section */}
                {isRuleExpanded && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                    <CreateRulePreview rule={selectedRule} />
                  </div>
                )}

                {/* Fix Expansion Section */}
                {isFixExpanded && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                    <CreateFixPreview
                      previewFix={previewFix}
                      isDraggable={false}
                    />
                  </div>
                )}

                {/* Render scheduled runs */}
                {scheduledRuns.length > 0 && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg space-y-2">
                    {renderScheduledRuns()}
                  </div>
                )}

                {/* Add a Scheduled Run Button */}
                {!isAddingRun && (
                  <div className="mt-10 ml-4 flex justify-left">
                    <button
                      onClick={() => setIsAddingRun(true)}
                      className="inline-flex text-sm items-center px-2 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-700"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                      Add a Scheduled Run
                    </button>
                  </div>
                )}

                {/* Scheduled Run Form */}
                {isAddingRun && (
                  <ScheduleForm
                    frequency={frequency}
                    setFrequency={setFrequency}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    handleAddRun={handleAddRun}
                    onCancel={() => setIsAddingRun(false)}
                  />
                )}

                {/* Save and Cancel Buttons */}
                <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent px-8 py-2 bg-indigo-500 text-white font-medium shadow-sm sm:ml-3 sm:w-auto sm:text-sm ${isSaving
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-indigo-700'
                      }`}
                    disabled={isSaving}
                    onClick={handleSave}
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-6 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsOpen(false)}
                    disabled={isSaving}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateSchedule;