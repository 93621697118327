import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const AvailableFields = ({ onInsertField, isExpanded, setIsExpanded, isEditable }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredFields, setFilteredFields] = React.useState([]);
  const [allFields, setAllFields] = React.useState([]); // Store all fields to reset the search

  const selectedObject = useSelector((state) => state.selectedObject);
  const salesforceObjects = useSelector((state) => state.salesforceObjects.data); 

  useEffect(() => {
    if (selectedObject && Array.isArray(salesforceObjects)) {
      const matchedObject = salesforceObjects.find((object) => object.name === selectedObject.name);
      if (matchedObject) {
        const allFieldsList = matchedObject.fields || [];
        // Filter fields with filterable = true
        const filterableFields = allFieldsList.filter(field => field.filterable);
        const nonFilterableFields = allFieldsList.filter(field => !field.filterable);


        // Set fields in state
        setAllFields(filterableFields); // Store all filterable fields for later use
        setFilteredFields(filterableFields); // Initially show all filterable fields
      }
    }
  }, [selectedObject, salesforceObjects]);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    if (searchValue === '') {
      // Reset the filtered fields to show all when search is cleared
      setFilteredFields(allFields);
    } else {
      // Filter fields based on the search term
      const filtered = allFields.filter((field) =>
        field.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredFields(filtered);
    }
  };

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev); // Use the prop function to toggle expansion state in the parent
  };

  return (
    <div className="mt-8 my-4">
      {/* Advanced button with chevron icon */}
      <button
        className="flex items-center text-indigo-600 text-xsm font-semibold ml-2 mb-2"
        onClick={toggleExpansion}
        type="button" // Ensure the button is not a submit button
      >
        {isExpanded ? (
          <ChevronDownIcon className="h-4 w-4 mr-1" />
        ) : (
          <ChevronRightIcon className="h-4 w-4 mr-1" />
        )}
        Advanced
      </button>

      {/* Expandable content */}
      {isExpanded && (
        <div className="mt-2 w-full">
          {/* Parent flex container to hold both elements side by side */}
          <div className="flex items-start space-x-4">
            {/* Left div for instructions */}
            <div className="flex items-center space-x-2">
              <p className="text-sm text-gray-600 whitespace-nowrap">
                Insert the exact <strong>{selectedObject.name}</strong> field name for accuracy:
              </p>
            </div>

            {/* Right div for search bar and results */}
            <div className="flex flex-col">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search for fields..."
                className="w-64 text-sm rounded-lg border border-gray-300 px-2 py-1 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                disabled={!isEditable} // Disable input when not editable
              />
              {/* Search results container */}
              <div className="mt-2 pb-20 bg-white shadow-md rounded-lg max-h-40 overflow-y-auto border border-gray-200 w-72">
                {filteredFields.length > 0 ? (
                  <ul>
                    {filteredFields.map((field) => (
                      <li
                        key={field.name}
                        className={`text-xsm px-4 py-2 ${
                          isEditable
                            ? 'cursor-pointer hover:bg-indigo-100'
                            : 'text-gray-400 cursor-not-allowed'
                        }`}
                        onClick={() => {
                          if (isEditable) {
                            onInsertField(field.name);
                          }
                        }}
                      >
                        {field.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 px-4 py-2 text-xsm">No fields available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableFields;