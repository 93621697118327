import React, { useState, useEffect } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd'; // Make sure this import is intact for DnD functionality
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './CreateFixStyle.jsx';
import { ArrowRightIcon, BoltIcon } from '@heroicons/react/24/solid';

const ItemType = 'CONDITION';

// Define the colors array for cycling
const colors = [
  'text-red-700',
  'text-blue-700',
  'text-green-700',
  'text-yellow-700',
];

// Condition Component for displaying each fieldCondition/action
const Condition = ({ condition, index, moveCondition, isDraggable }) => {
  const ref = React.useRef(null);

  // Conditionally set up DnD based on the isDraggable prop
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (!ref.current || !isDraggable) return; // Do not use drop functionality if isDraggable is false
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      moveCondition(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: isDraggable, // Only allow drag if isDraggable is true
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Enable drag and drop only if isDraggable is true
  if (isDraggable) {
    drag(drop(ref)); // Enable DnD when isDraggable is true
  }

  const usesAI = condition.fieldConditions.some((fc) => fc.fieldConditionType === 'ai') || condition.actions.some((action) => action.actionType === 'ai');

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: isDraggable ? (isDragging ? 'grabbing' : 'grab') : 'default' }}
      className="bg-white border border-gray-300 rounded-md px-4 py-2"
    >
      <div className="flex justify-between items-center mb-3">
        <div className="text-xl font-semibold text-[#8A79EC]">{condition.priorityOrder}</div>
        {usesAI && (
          <div className="flex items-center text-indigo-500">
            <span className="text-sm mr-1">GPT-4o</span>
            <BoltIcon className="h-5 w-5" />
          </div>
        )}
      </div>

      <div className="flex text-xsm items-center">
        <div className="w-1/2 flex flex-col space-y-3 p-3 pt-4 bg-[#f9f9f9] border border-gray-300 rounded-lg">
          {condition.fieldConditions.map((fieldCondition, idx) => (
            <div key={fieldCondition.fieldConditionKey} className="flex items-center space-x-1">
              {fieldCondition.fieldConditionType === 'allRecords' ? (
                <span className="text-gray-900 font-semibold font-mono">All Records</span>
              ) : fieldCondition.fieldConditionType === 'hard' ? (
                <>
                  <span className={`${styles.fieldName} ${colors[idx % colors.length]}`}>{fieldCondition.fieldName}</span>
                  <span className={styles.operator}>{fieldCondition.operator}</span>
                  <span className={styles.conditionValue}>{fieldCondition.conditionValue}</span>
                </>
              ) : (
                <div className="relative w-full bg-white p-1 px-2 border border-indigo-200 rounded-md">
                  <span className={`${styles.aiInstructions} pr-8`}>{fieldCondition.aiInstructions}</span>
                  <BoltIcon className="absolute bottom-1 right-1 h-5 w-5 text-indigo-500" />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center w-12">
          <ArrowRightIcon className="h-8 w-8 text-indigo-500" />
        </div>
        <div className="w-1/2 flex flex-col space-y-4 p-3 bg-[#f9f9f9] border border-gray-300 rounded-lg">
          {condition.actions.map((action) => (
            <div key={action.actionKey} className="flex items-center space-x-1">
              {action.actionType === 'hard' ? (
                <>
                  <span className={styles.fieldName}>{action.fieldName}</span>
                  <span className={styles.operator}>=</span>
                  <span className={styles.newValue}>{action.newValue}</span>
                </>
              ) : (
                <div className="relative w-full bg-white p-1 px-2 border border-indigo-200 rounded-md">
                  <span className={`${styles.aiInstructions}`}>{action.aiInstructions}</span>
                  <BoltIcon className="absolute bottom-1 right-1 h-5 w-5 text-indigo-500" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 text-xsm text-gray-600">{condition.conditionDescription}</div>
    </div>
  );
};

// Droppable Container Component
const CreateFixPreview = ({ previewFix, setPreviewFix, isDraggable = true }) => {
  const [conditions, setConditions] = useState(previewFix.conditions || []);

  useEffect(() => {
    if (previewFix && previewFix.conditions) {
      setConditions(previewFix.conditions);
    }
  }, [previewFix]);

  const moveCondition = (dragIndex, hoverIndex) => {
    const newConditions = [...conditions];
    const [movedCondition] = newConditions.splice(dragIndex, 1);
    newConditions.splice(hoverIndex, 0, movedCondition);

    const updatedConditions = newConditions.map((condition, index) => ({
      ...condition,
      priorityOrder: index + 1,
    }));

    setConditions(updatedConditions);
    setPreviewFix({ ...previewFix, conditions: updatedConditions });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <h1 className="text-sm font-semibold mb-4">Drag and drop to set the order of evaluation:</h1>
        <div className="space-y-4">
          {conditions.map((condition, index) => (
            <Condition
              key={condition.priorityOrder}
              index={index}
              condition={condition}
              moveCondition={moveCondition}
              isDraggable={isDraggable} // Pass isDraggable prop to each condition
            />
          ))}
        </div>
      </div>
    </DndProvider>
  );
};

export default CreateFixPreview;