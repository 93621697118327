import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

export const fetchRules = createAsyncThunk(
  'rules/fetchRules',
  async () => {
    const response = await axiosInstance.get('/api/rules');
    return response.data;
  }
);

// Fetch a specific rule by ID using axiosInstance
export const fetchRuleById = createAsyncThunk(
  'rules/fetchRuleById',
  async (ruleId) => {
    const response = await axiosInstance.get(`/api/rules/${ruleId}`);
    return response.data;
  }
);

const rulesSlice = createSlice({
  name: 'rules',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    updateRuleDirectly: (state, action) => {
      const index = state.data.findIndex(rule => rule._id === action.payload._id);
      if (index >= 0) {
        state.data[index] = action.payload;
      } else {
        state.data.push(action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRuleById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRuleById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(rule => rule._id === action.payload._id);
        if (index >= 0) {
          state.data[index] = action.payload;
        } else {
          state.data.push(action.payload);
        }
      })
      .addCase(fetchRuleById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { updateRuleDirectly } = rulesSlice.actions; // Export the new action

export default rulesSlice.reducer;