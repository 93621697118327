import React, { useEffect, useState, useCallback, useRef } from 'react';
import axiosInstance from '../../axiosInstance.js';
import io from 'socket.io-client';
import RunEvent from './RunEvent.jsx';
import FixEvent from './FixEvent.jsx';
import ActionEvent from './ActionEvent.jsx';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import '../../index.css';

const Live = () => {
    const [events, setEvents] = useState([]);
    const [eventQueue, setEventQueue] = useState([]);
    const [processingEvent, setProcessingEvent] = useState(false);
    const [socketToken, setSocketToken] = useState(null);
    const isLiveViewActive = useSelector((state) => state.ui.isLiveActive);
    const socket = useRef(null);

    const socketUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:5001' 
        : process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (isLiveViewActive) {
            axiosInstance.get('/api/events')
                .then((response) => {
                    setEvents(response.data.slice(0, 100));
                })
                .catch((error) => {
                    console.error('Error fetching initial events:', error);
                });
        }
    }, [isLiveViewActive]);

    useEffect(() => {
        if (isLiveViewActive) {
            axiosInstance.post('/api/request-socket')
                .then((response) => {
                    const { socketToken } = response.data;
                    setSocketToken(socketToken);
                })
                .catch((error) => {
                    console.error('Error fetching socket token:', error);
                });
        }
    }, [isLiveViewActive]);

    useEffect(() => {
        if (isLiveViewActive && socketToken) {
            socket.current = io(socketUrl, {
                query: { token: socketToken },
                autoConnect: false,
                withCredentials: true,
            });

            socket.current.connect();

            socket.current.on('connect', () => {
                console.log('Connected to socket server');
            });

            socket.current.on('newEvent', (event) => {
                const structuredEvent = {
                    ...event,
                    ruleName: event.ruleName || 'Unknown Rule',
                    issueCount: event.issueCount !== undefined ? event.issueCount : 'an unknown number of',
                };
                setEventQueue((prevQueue) => [...prevQueue, structuredEvent]);
            });

            socket.current.on('disconnect', () => {
                console.log('Disconnected from socket server');
            });

            return () => {
                if (socket.current) {
                    socket.current.off('newEvent');
                    socket.current.disconnect();
                }
            };
        }
    }, [isLiveViewActive, socketToken, socketUrl]);

    const processNextEvent = useCallback(() => {
        if (eventQueue.length > 0 && !processingEvent) {
            setProcessingEvent(true);
            const nextEvent = eventQueue[0];

            setEvents((prevEvents) => {
                const newEvents = [nextEvent, ...prevEvents];
                const uniqueEvents = newEvents.filter((event, index, self) =>
                    index === self.findIndex((e) => e._id === event._id)
                );
                return uniqueEvents.slice(0, 100);
            });

            setTimeout(() => {
                setEventQueue((prevQueue) => prevQueue.slice(1));
                setProcessingEvent(false);
            }, 500);
        }
    }, [eventQueue, processingEvent]);

    useEffect(() => {
        if (!processingEvent && eventQueue.length > 0) {
            processNextEvent();
        }
    }, [eventQueue, processingEvent, processNextEvent]);

    const renderEvent = (event) => {
        switch (event.type) {
            case 'ruleRun':
                return <RunEvent key={event._id.toString()} event={event} onComplete={handleComplete} />;
            case 'fixRun':
                return <FixEvent key={event._id.toString()} event={event} />;
            case 'action':
                return <ActionEvent key={event._id.toString()} event={event} onComplete={handleComplete} />;
            default:
                return null;
        }
    };

    const handleComplete = useCallback(() => {
        setProcessingEvent(false);
    }, []);

    return (
        <div className="w-full h-[calc(100vh-140px)] mt-2 mr-4 mb-16">
            {/* Header with flexbox styling */}
            <div className="sticky top-0 z-10 py-2 pb-4 flex justify-between items-center">
                <h1 className="text-2xl font-bold mt-8 mb-4 ml-6">Live</h1>
                {/* Wrap <l-quantum> in a container div */}
                <div className="mr-10 mt-4 relative">
                    <l-quantum size="40" speed="7.0" color="#8A79EC"></l-quantum>
                </div>
            </div>

            {/* Static rounded container with a fixed height */}
            <div className="border border-gray-200 rounded-lg px-2 h-[calc(100vh-240px)] pt-2">
                {/* Scrollable content inside the static container */}
                <div className="overflow-auto h-full event-list text-xs space-y-4">
                    <AnimatePresence initial={false}>
                        {events.map((event) => (
                            <motion.div
                                key={event._id}
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 50 }}
                                transition={{ duration: .75, ease: "easeOut" }}
                                layout // This prop enables layout animations
                            >
                                {renderEvent(event)}
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
};

export default Live;