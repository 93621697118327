// CreateFixStyle.js
const styles = {
    fieldName: 'inline-block text-xs bg-white p-1 px-2 rounded border font-mono',
    operator: 'font-mono text-xsm text-gray-700',
    conditionValue:
      'inline-block text-xs bg-white p-1 px-2 rounded border font-mono text-black',
    aiInstructions:
      'font-mono text-xs p-1 px-2 text-purple-700',
    newValue:
      'inline-block text-xs bg-white p-1 px-2 rounded border font-mono text-indigo-700',
  };
  
  export default styles;