import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon, CreditCardIcon, BoltIcon } from '@heroicons/react/24/solid';
import { setIsBuyCreditsOpen } from '../../slices/uiSlice';
import { fetchOrgCredits } from '../../slices/orgCreditsSlice'; // Import the thunk for fetching credits
import BuyLightning from './BuyLightning.jsx';
import BuyStarter from './BuyStarter.jsx';

const BuyCredits = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.ui.isBuyCreditsOpen);
    const credits = useSelector((state) => state.orgCredits.credits); // Get credits from the Redux state

    // State to manage the selected package
    const [selectedPackage, setSelectedPackage] = useState('starter'); // 'starter' is selected by default

    const closeBuyCredits = () => {
        dispatch(setIsBuyCreditsOpen(false));
    };

    // Fetch credits when the component mounts
    useEffect(() => {
        if (isOpen) {
            dispatch(fetchOrgCredits());
        }
    }, [dispatch, isOpen]);

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog open={isOpen} onClose={closeBuyCredits} className="relative z-10">
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 scale-95"
                            enterTo="opacity-100 translate-y-0 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 scale-100"
                            leaveTo="opacity-0 translate-y-4 scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={closeBuyCredits}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div>
                                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900 flex items-center mb-6">
                                        <CreditCardIcon className="h-8 w-8 mr-4" aria-hidden="true" />
                                        Choose a credit package
                                        <span className="text-sm text-gray-500 ml-2">
                                            (<strong>{credits !== null ? credits.toFixed(2) : 'Loading...'}</strong> credits remaining)
                                        </span>
                                    </DialogTitle>
                                </div>

                                <div className="flex">
                                    {/* Package Selection on the Left */}
                                    <div className="flex flex-col space-y-4 w-1/3">
                                        {/* Starter Package */}
                                        <div
                                            className={`flex flex-col items-center justify-center p-4 rounded-lg border cursor-pointer ${selectedPackage === 'starter' ? 'ring-2 ring-indigo-500' : 'border-gray-300'
                                                }`}
                                            onClick={() => setSelectedPackage('starter')}
                                        >
                                            <div className="text-lg font-medium">Starter</div>
                                            <div className="text-sm text-gray-500">75 Credits</div>
                                        </div>

                                        {/* Lightning Package */}
                                        <div
                                            className={`flex flex-col items-center justify-center p-4 rounded-lg border cursor-pointer ${selectedPackage === 'lightning' ? 'ring-2 ring-indigo-500' : 'border-gray-300'
                                                }`}
                                            onClick={() => setSelectedPackage('lightning')}
                                        >
                                            <div className="text-lg font-medium flex items-center">
                                                Lightning
                                                <BoltIcon className="h-5 w-5 ml-2 text-indigo-600" aria-hidden="true" />
                                            </div>
                                            <div className="text-sm text-gray-500">300 Credits</div>
                                        </div>
                                    </div>

                                    {/* Selected Package Component on the Right */}
                                    <div className="w-2/3 pl-6">
                                        {selectedPackage === 'starter' ? <BuyStarter /> : <BuyLightning />}
                                    </div>
                                </div>


                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default BuyCredits;