import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSalesforceObjects } from './slices/salesforceObjectsSlice.js';
import { setSelectedObject } from './slices/selectedObjectSlice.js';
import { fetchRules } from './slices/rulesSlice.js';
import { fetchIssues } from './slices/issuesSlice.js';
import { setSelectedRuleId } from './slices/selectedRuleIdSlice.js';
import { fetchUser } from './slices/userSlice.js';
import { fetchOrganization } from './slices/organizationSlice.js';
import { fetchOrgCredits } from './slices/orgCreditsSlice.js'; // Import the orgCredits thunk
import { addNotification } from './slices/notificationSlice';
import {
  setIsCreateRuleOpen,
  setIsCreateFixOpen,
  setIsLiveActive,
  setIsBuyCreditsOpen,
  setIsCreateScheduleOpen,
  setIsScheduleActive,
  setIsGeneralFeedbackOpen,
  setIsAuditActive,
  setIsSettingsActive, // Import the feedback action
} from './slices/uiSlice';
import Header from './components/header/Header.jsx';
import CreateRule from './components/rules/CreateRule.jsx';
import CreateFix from './components/fixes/CreateFix.jsx';
import GeneralFeedback from './components/header/GeneralFeedback.jsx'; // Import GeneralFeedback component
import Navigation from './components/navigation/Navigation.jsx';
import Notification from './components/Notification';
import RuleColumn from './components/rules/RuleColumn.jsx';
import IssueColumn from './components/issues/IssueColumn.jsx';
import Footer from './components/footer/Footer.jsx';
import Live from './components/live/Live.jsx';
import Schedule from './components/schedule/schedule-view/Schedule.jsx';
import { mirage, quantum, ring } from 'ldrs';
import * as Sentry from '@sentry/react';
import Login from './components/login/Login.jsx'; // Import the new Login component
import axiosInstance from './axiosInstance.js';
import OutOfCredits from './components/OutOfCredits';
import { setIsOutOfCreditsOpen } from './slices/uiSlice'; // Import the new action
import ProvisioningCustomer from './components/login/ProvisioningCustomer.jsx';
import { setIsProvisioning } from './slices/uiSlice';
import AddSalesforceObject from './components/navigation/AddSalesforceObject.jsx';
import { setIsAddSalesforceObjectOpen } from './slices/uiSlice';
import GetContactEmail from './components/GetContactEmail.jsx';
import { setIsGetContactEmailOpen } from './slices/uiSlice.js';
import ErrorBoundary from './components/errors/ErrorBoundary.jsx';
import logErrorToServer from './components/errors/errorLogger.js';
import SomeComponent from './components/SomeComponent.jsx';
import CreateSchedule from './components/schedule/create-schedule/CreateSchedule.jsx';
import ReleaseNotesNotif from './components/ReleaseNotesNotif.jsx';
import OldData from './components/issues/OldData.jsx';
import Audit from './components/audit/Audit.jsx';
import Settings from './components/settings/Settings.jsx';
import PreventRun from './components/PreventRun.jsx';
import BuyCredits from './components/buy-credits/BuyCredits.jsx';

function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login status
  const [loading, setLoading] = useState(true); // Loading state for async actions

  // Redux state management
  const isLiveActive = useSelector((state) => state.ui.isLiveActive);
  const isScheduleActive = useSelector((state) => state.ui.isScheduleActive);
  const isSettingsActive = useSelector((state) => state.ui.isSettingsActive);
  const isAuditActive = useSelector((state) => state.ui.isAuditActive);
  const isGeneralFeedbackOpen = useSelector((state) => state.ui.isGeneralFeedbackOpen); // Add feedback modal state
  const salesforceObjectsStatus = useSelector((state) => state.salesforceObjects.status);
  const rulesStatus = useSelector((state) => state.rules.status);
  const issuesStatus = useSelector((state) => state.issues.status);
  const salesforceObjects = useSelector((state) => state.salesforceObjects.data);
  const selectedObject = useSelector((state) => state.selectedObject);
  const rules = useSelector((state) => state.rules.data);
  const issues = useSelector((state) => state.issues.data);
  const isCreateRuleOpen = useSelector((state) => state.ui.isCreateRuleOpen);
  const isCreateFixOpen = useSelector((state) => state.ui.isCreateFixOpen);
  const selectedRuleId = useSelector((state) => state.selectedRuleId);
  const selectedRule = rules.find((rule) => rule._id === selectedRuleId);
  // Fetch the organization credits from the Redux store
  const credits = useSelector((state) => state.orgCredits.credits);
  const isOutOfCreditsOpen = useSelector((state) => state.ui.isOutOfCreditsOpen);
  const isProvisioning = useSelector((state) => state.ui.isProvisioning);
  const isAddSalesforceObjectOpen = useSelector((state) => state.ui.isAddSalesforceObjectOpen);
  const isBuyCreditsOpen = useSelector((state) => state.ui.isBuyCreditsOpen);
  const isCreateScheduleOpen = useSelector((state) => state.ui.isCreateScheduleOpen);
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);


  mirage.register();
  quantum.register();

  // Check if user is authenticated
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axiosInstance.get('/auth/check-auth');
        setIsLoggedIn(response.data.isAuthenticated);
        setIsProvisioning(false);
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsLoggedIn(false);
      } finally {
        setLoading(false); // Done loading
      }
    };

    checkAuth();
  }, []);

  // Trigger notification if credits are less than 5 and the user is logged in
  useEffect(() => {
    if (credits !== null && credits < 5 && !loading) {
      dispatch(addNotification({ message: "You're low on credits!", type: 'warning' }));
    }
  }, [credits, dispatch, loading]);

  useEffect(() => {
    if (credits !== null && credits < 0 && !loading) {
      dispatch(setIsOutOfCreditsOpen(true)); // Show the OutOfCredits modal when credits are below 0
    } else {
      dispatch(setIsOutOfCreditsOpen(false)); // Hide the modal when credits are sufficient
    }
  }, [credits, dispatch, loading]);

  const handleSalesforceConnection = async () => {
    dispatch(addNotification({ message: 'Requesting updated Salesforce Configuration', description: '', type: 'info' }));
    try {
      const response = await axiosInstance.get('/api/salesforce-config/false'); // Use 'false' as the parameter
      await dispatch(fetchSalesforceObjects()).unwrap(); // Wait for fetchSalesforceObjects to complete
      dispatch(addNotification({ message: 'Salesforce Configuration is up to date within the last 24 hours. You can refresh this in the User Menu (lower left of screen).', description: '', type: 'success' }));
    } catch (error) {
      console.error('Error fetching Salesforce configuration:', error);
      dispatch(addNotification({ message: 'Error updating Salesforce Configuration', description: error.message, type: 'error' }));
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      // Wrap fetching both user and organization data in a Promise.all
      Promise.all([dispatch(fetchUser()).unwrap(), dispatch(fetchOrganization()).unwrap()])
        .then(([userData, orgData]) => {
          // Check if either the user is missing contactEmail or the organization is named "placeholder"
          if (!userData?.contactEmail || orgData?.name === 'placeholder') {
            dispatch(setIsGetContactEmailOpen(true)); // Open GetContactEmail modal
          }
        })
        .catch((error) => {
          console.error('Error fetching user or organization data:', error);
        });

      // Call handleSalesforceConnection if the user has just logged in
      handleSalesforceConnection();
    }
  }, [isLoggedIn, dispatch]);


  useEffect(() => {
    if (salesforceObjectsStatus === 'idle' && isLoggedIn) {
      dispatch(fetchSalesforceObjects());
    }
  }, [salesforceObjectsStatus, dispatch, isLoggedIn]);

  useEffect(() => {
    if (rulesStatus === 'idle' && isLoggedIn) {
      dispatch(fetchRules());
    }
  }, [rulesStatus, dispatch, isLoggedIn]);

  useEffect(() => {
    if (issuesStatus === 'idle' && isLoggedIn) {
      dispatch(fetchIssues());
    }
  }, [issuesStatus, dispatch, isLoggedIn]);

  const handleObjectClick = (object) => {
    dispatch(setSelectedObject(object));
    dispatch(setSelectedRuleId(null)); // Reset the selected rule when a new object is selected
    dispatch(setIsLiveActive(false)); // Deactivate Live when an object is selected
    dispatch(setIsScheduleActive(false));
    dispatch(setIsAuditActive(false));
    dispatch(setIsSettingsActive(false));
  };

  const handleRuleClick = (rule) => {
    dispatch(setSelectedRuleId(rule._id));
  };

  const filteredRules = selectedObject
    ? rules.filter((rule) => rule.salesforceObject === selectedObject.name)
    : [];

  const filteredIssues = selectedRuleId
    ? issues.filter((issue) => issue.ruleId === selectedRuleId)
    : [];

  // Example of dispatching the provisioning state
  const startProvisioning = () => {
    dispatch(setIsProvisioning(true));
  };

  const stopProvisioning = () => {
    dispatch(setIsProvisioning(false));
  };

  useEffect(() => {
    if (loading) {
      startProvisioning(); // Set provisioning state to true while loading
    } else {
      stopProvisioning(); // Once loading is complete, stop provisioning
    }
  }, [loading]);

  if (isProvisioning) {
    return <ProvisioningCustomer />; // Show provisioning component if provisioning
  }

  if (!isLoggedIn) {
    return <Login />; // Show login screen if not authenticated
  }

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ErrorBoundary onError={logErrorToServer}>
        <div className="relative isolate min-h-full flex-col h-screen bg-white">
          <Header />
          <ReleaseNotesNotif />
          <div className="flex-1 mx-auto flex w-full max-w-8xl items-start gap-x-4 overflow-hidden">
            <Navigation
              salesforceObjects={salesforceObjects}
              onSelectObject={handleObjectClick}
              selectedObject={selectedObject}
            />
            {isScheduleActive ? (
              <Schedule /> // Render the Schedule component when active
            ) : isLiveActive ? (
              <Live /> // Render the Live component when active
            ) : isAuditActive ? (
              <Audit /> // Render the Audit component when active
            ) : isSettingsActive ? (
              <Settings /> // Render the Audit component when active
            ) : (
              <>
                <RuleColumn
                  selectedObject={selectedObject}
                  filteredRules={filteredRules}
                  handleRuleClick={handleRuleClick}
                />
                <IssueColumn
                  selectedRuleId={selectedRuleId}
                  filteredIssues={filteredIssues}
                />
              </>
            )}
          </div>

          <CreateRule
            isOpen={isCreateRuleOpen}
            setIsOpen={(isOpen) => dispatch(setIsCreateRuleOpen(isOpen))}
            selectedSalesforceObject={selectedObject}
          />
          <CreateFix
            isOpen={isCreateFixOpen}
            setIsOpen={(isOpen) => dispatch(setIsCreateFixOpen(isOpen))}
            selectedSalesforceObject={selectedObject}
          />
          <BuyCredits
            isOpen={isBuyCreditsOpen}
            setIsOpen={(isOpen) => dispatch(setIsBuyCreditsOpen(isOpen))}
          />
          <CreateSchedule
            isOpen={isCreateScheduleOpen}
            setIsOpen={(isOpen) => dispatch(setIsCreateScheduleOpen(isOpen))}
            selectedRule={selectedRule} // Pass the selectedRule if needed
          />
          <GeneralFeedback
            isOpen={isGeneralFeedbackOpen}
            setIsOpen={(isOpen) => dispatch(setIsGeneralFeedbackOpen(isOpen))}
          />
          <OutOfCredits
            isOpen={isOutOfCreditsOpen} // Pass Redux state
            setIsOpen={(isOpen) => dispatch(setIsOutOfCreditsOpen(isOpen))} // Pass dispatch function
          />
          <AddSalesforceObject
            isOpen={isAddSalesforceObjectOpen} // Use the existing variable
            setIsOpen={(isOpen) => dispatch(setIsAddSalesforceObjectOpen(isOpen))} // Dispatch action to update state
          />
          <GetContactEmail />
          <OldData />
          <PreventRun />
          <Notification />
          <Footer />
        </div>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
}

export default App;