// selectedRuleIdSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = null; // No rule selected initially

const selectedRuleIdSlice = createSlice({
  name: 'selectedRuleId',
  initialState,
  reducers: {
    setSelectedRuleId: (state, action) => action.payload, // Set selected rule ID
    clearSelectedRuleId: () => null, // Clear the selected rule ID
  },
});

export const { setSelectedRuleId, clearSelectedRuleId } = selectedRuleIdSlice.actions;
export default selectedRuleIdSlice.reducer;