import React from 'react';
import {
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';

const ScheduledRun = ({ frequency, startDate, onRemove }) => {
  const formattedTime = new Date(startDate).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });
  const formattedDate = new Date(startDate).toLocaleDateString();

  return (
    <div className="p-4 border border-indigo-400 rounded-md bg-gray-100 flex justify-between items-center">
      {/* Run Frequency and Next Run */}
      <div className ="w-1/8"><CalendarDaysIcon className="h-6 w-6 mr-2" aria-hidden="true" /></div>
      <div className = "w-3/4">
        <p className="text-sm text-gray-700">
          Running <span className="font-bold">{frequency}</span>
        </p>
        <p className="text-sm pt-1 text-gray-700">
         Next Run: <strong>{formattedTime}</strong> on {formattedDate}
        </p>
      </div>

      {/* Cancel Button */}
      <button
        onClick={onRemove}
        className="text-red-500 text-sm hover:text-red-700 bg-red-100 px-2 py-1 rounded-lg"
      >
        Cancel
      </button>
    </div>
  );
};

export default ScheduledRun;