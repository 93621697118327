// ProvisioningCustomer.jsx

import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { lineWobble } from 'ldrs';

const ProvisioningCustomer = () => {
    console.log('provisioning component mounted');
    
    lineWobble.register();

    return (
    <div className="min-h-screen flex flex-col justify-center bg-[#f9f9f9]">
      <div className="sm:mx-auto pr-2 sm:w-full sm:max-w-md">
        {/* Lock Closed Icon */}
        <LockClosedIcon className="mx-auto h-20 w-20 text-[#8A79EC]" />

        {/* Company Logo */}
        <img
          alt="Your Company"
          src="/assets/tarn_logo_purple.png"
          className="mx-auto h-20 w-auto mt-4"
        />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-6 py-12 sm:rounded-lg sm:px-12">
          {/* Text */}
          <div className="space-y-6 text-center">
            <p className="text-gray-900 pb-4">
              Creating your secure <span className="font-semibold">tarnData</span> instance...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvisioningCustomer;