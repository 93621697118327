import React from 'react';
import { useSelector } from 'react-redux';
import HeroIcon from '../../HeroIcon.jsx'; // Adjust the path as necessary

const ScheduledRule = ({ schedule }) => {
  const { ruleName, salesforceObject, projectedRun, isNextRun, frequency } = schedule;
  const salesforceObjects = useSelector((state) => state.salesforceObjects.data);
  const matchingObject = salesforceObjects.find((obj) => obj.name === salesforceObject);
  const heroIcon = matchingObject?.heroIcon;

  // Formatting the time without a leading zero for hours
  const formattedTime = new Date(projectedRun).toLocaleTimeString([], {
    hour: 'numeric', // This removes leading zero
    minute: '2-digit',
  });

  return (
    <div
      className={`p-2 mb-2 rounded shadow ${
        isNextRun ? 'bg-indigo-100 border-l-4 border-indigo-500' : 'bg-white'
      }`}
    >
      <div className="flex items-center">
        {/* Left div with time */}
        <div className="flex items-center justify-center w-1/4 pl-1">
          <p className="text-sm font-semibold text-gray-900 whitespace-nowrap">{formattedTime}</p>
        </div>

        {/* Right div with hero icon, ruleName, and frequency, allowing text to wrap */}
        <div className="w-3/4 flex items-center pl-5">
          {heroIcon && (
            <HeroIcon iconName={heroIcon} type="solid" className="h-10 w-10 text-gray-500" />
          )}
          <p className="text-xs pl-2 font-medium text-gray-900 break-words">
            {ruleName} {frequency}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScheduledRule;