// Helper function to convert JSON to CSV
const convertToCSV = (data) => {
    const headers = ['Salesforce ID', 'Field Name', 'Old Value', 'New Value'];
    const rows = data.map((item) => [
      item.salesforceId,
      item.fieldName,
      item.oldValue,
      item.newValue,
    ]);
  
    // Create CSV content
    let csvContent = `${headers.join(',')}\n`;
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });
    
    return csvContent;
  };
  
  // Function to trigger the CSV download
  const downloadCSV = (csvContent, runId) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `run_${runId}_details.csv`;
    a.click();
    window.URL.revokeObjectURL(url); // Clean up
  };
  
  // Main function to be called
  const downloadDetails = (runId, details) => {
    if (!details || details.length === 0) {
      console.error('No details available to download');
      return;
    }
  
    const csvContent = convertToCSV(details);
    downloadCSV(csvContent, runId);
  };
  
  export default downloadDetails;