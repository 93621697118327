import React from 'react';

export default function NotificationsSettings() {
  return (
    <div className="text-gray-700">
      <h2 className="text-lg font-semibold">Notifications Settings</h2>
      <p className="mt-2 text-sm">This is where you can configure your notification preferences.</p>
      {/* Add notification-specific settings here */}
    </div>
  );
}