import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance.js';

// Thunk for fetching all Salesforce objects
export const fetchSalesforceObjects = createAsyncThunk(
  'salesforceObjects/fetchSalesforceObjects',
  async () => {
    const response = await axiosInstance.get('/api/salesforceobjects');
    return response.data;
  }
);

// Thunk for fetching a single Salesforce object
export const fetchSalesforceObjectByName = createAsyncThunk(
  'salesforceObjects/fetchSalesforceObjectByName',
  async (name) => {
    const response = await axiosInstance.get(`/api/salesforceobjects/${name}`);
    return response.data;
  }
);

const salesforceObjectsSlice = createSlice({
  name: 'salesforceObjects',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesforceObjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSalesforceObjects.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSalesforceObjects.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchSalesforceObjectByName.fulfilled, (state, action) => {
        const updatedObject = action.payload;
        const index = state.data.findIndex(obj => obj.name === updatedObject.name);
        if (index !== -1) {
          // Update the existing object in the data array
          state.data[index] = updatedObject;
        } else {
          // Add the object if it doesn't already exist
          state.data.push(updatedObject);
        }
      });
  },
});

export default salesforceObjectsSlice.reducer;