import React from 'react';
import moment from 'moment'; // For formatted timestamps
import SalesforceObjectIcon from '../rules/SalesforceObjectIcon';
import { BoltIcon } from '@heroicons/react/24/outline';

const ActionEvent = ({ event, onComplete }) => {
  const fieldName = event.fieldName || 'Unknown Field';
  const oldValue = event.oldValue !== undefined ? event.oldValue : 'unknown';
  const newValue = event.newValue !== undefined ? event.newValue : 'unknown';
  const timestamp = event.timestamp || new Date();

  // Extract `recordName` and `linkToRecord` directly from the `event` object
  const recordName = event.recordName || 'Unknown Record';
  const linkToRecord = event.linkToRecord || '#';

  return (
    <div className="p-2 bg-[#f9f9f9] border rounded-lg mb-2 flex items-center justify-between">
      {/* Icons div */}
      <div className="flex items-center mr-2 flex-shrink-0">
        <BoltIcon className="h-4 w-4 text-indigo-600 mr-1" aria-hidden="true" />
        <SalesforceObjectIcon objectName={event.salesforceObject} />
      </div>

      {/* Action text div */}
      <div className="flex-grow mx-2">
        <span className="font-bold">Action:</span>{' '}
        <span className="inline-block p-1 px-2 ml-1 rounded border">
          <span className="font-mono text-indigo-600">
            {fieldName}
          </span>
        </span>{' '}
        was updated from{' '}
        <span className="inline-block p-1 pl-2 ml-1 rounded border">
          <span className="font-mono font-semibold pr-1 text-gray-700">
            {oldValue}
          </span>
        </span>{' '}
        to{' '}
        <span className="inline-block p-1 pl-2 ml-1 rounded border">
          <span className="font-mono font-semibold pr-1 text-indigo-600">
            {newValue}
          </span>
        </span>
        {recordName && linkToRecord && (
          <>
            {' '}
            on{' '}
            <span className="inline-flex items-center">
              <a
                href={linkToRecord}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:underline no-underline ml-1"
              >
                {recordName}
              </a>
              <img
                src="/assets/Salesforce_Corporate_Logo_RGB.png"
                alt="Salesforce Logo"
                className="h-4 w-auto ml-2"
              />
            </span>
          </>
        )}
      </div>

      {/* Timestamp div */}
      <div className="text-right ml-2 flex-shrink-0">
        <p className="font-semibold text-gray-900 text-center">
          {moment(timestamp).format('h:mm:ss a')}
        </p>
        <p className="text-gray-600">
          {moment(timestamp).format('MMMM Do YYYY')}
        </p>
      </div>
    </div>
  );
};

export default ActionEvent;