import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPreventRunOpen, setPreventRunMessage } from '../slices/uiSlice.js';

const PreventRun = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isPreventRunOpen);
  const message = useSelector((state) => state.ui.preventRunMessage);
  const seconds = useSelector((state) => state.ui.preventRunSeconds); // New attribute for initial seconds

  console.log(seconds);

  const [secondsLeft, setSecondsLeft] = useState(seconds);

  useEffect(() => {
    // Only start the countdown if initial seconds are available and the component is open
    if (isOpen && seconds > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      // Cleanup interval on component unmount or when countdown stops
      return () => clearInterval(timer);
    }
  }, [isOpen, seconds]);

  // Update the message dynamically with the countdown
  const dynamicMessage = message
    ? message.replace(/\d+\sseconds/, `${secondsLeft} seconds`)
    : '';

  const handleClose = () => {
    dispatch(setIsPreventRunOpen(false));
    dispatch(setPreventRunMessage(null)); // Clear message on close
  };

  if (!isOpen || !message) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-md text-center">
        <h2 className="text-xl font-semibold text-gray-900">Run Prevented</h2>
        <p className="mt-2 text-gray-700">{dynamicMessage}</p>
        <button
          onClick={handleClose}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500"
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default PreventRun;