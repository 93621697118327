import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

const ValidateFix = ({ previewFix, onValidationStatusChange }) => {
  const [isValidating, setIsValidating] = useState(true);
  const [validationStatus, setValidationStatus] = useState(null); // 'valid' or 'invalid'
  const [invalidFields, setInvalidFields] = useState([]);

  // Get the selected Salesforce object from Redux
  const selectedSalesforceObject = useSelector((state) => state.selectedObject);

  useEffect(() => {
    // Exit early if previewFix is not provided
    if (!previewFix || !selectedSalesforceObject) return;

    setIsValidating(true);
    let allFieldsUpdateable = true;
    const invalidFieldsList = [];

    previewFix.conditions.forEach((condition) => {
      condition.actions.forEach((action) => {
        const field = selectedSalesforceObject.fields.find(
          (field) => field.name === action.fieldName
        );

        if (field && !field.updateable) {
          allFieldsUpdateable = false;
          invalidFieldsList.push(field.name);
        }
      });
    });

    setIsValidating(false);
    setInvalidFields(invalidFieldsList);
    const newValidationStatus = allFieldsUpdateable ? 'valid' : 'invalid';

    // Only call onValidationStatusChange if the status has changed
    if (newValidationStatus !== validationStatus) {
      setValidationStatus(newValidationStatus);
      onValidationStatusChange(newValidationStatus === 'valid');
    }
  }, [previewFix, selectedSalesforceObject, onValidationStatusChange]);

  // If previewFix is null or undefined, do not render anything
  if (!previewFix) return null;

  return (
    <div className="w-full rounded-lg border border-gray-300 bg-[#f9f9f9] p-4">
      {isValidating ? (
        <div className="flex justify-center items-center space-x-2">
          <span className="text-gray-600 text-sm font-semibold">Validating Fix...</span>
        </div>
      ) : validationStatus === 'valid' ? (
        <div className="flex justify-center items-center space-x-2">
          <span className="text-green-600 text-xsm font-semibold">Fix Validated</span>
          <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
        </div>
      ) : (
        <div>
          <div className="flex items-center space-x-2">
            <span className="text-red-600 text-sm font-semibold">Fix Validation Failed</span>
            <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
          </div>
          <ul className="list-disc pl-5 mt-2 space-y-1 text-xsm text-gray-600">
            {invalidFields.map((fieldName, index) => (
              <li key={index}>
                The <strong>{fieldName}</strong> field cannot be updated - this is a Salesforce restriction
              </li>
            ))}
            <li>
              Try a new prompt with fields that can be updated in Salesforce
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ValidateFix;