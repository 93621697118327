import React, { useState, useEffect } from 'react';
import { Dialog, Transition, DialogPanel, DialogTitle } from '@headlessui/react';
import {
  XMarkIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGeneralFeedbackOpen } from '../../slices/uiSlice.js';
import axiosInstance from '../../axiosInstance.js';
import AttachScreenshot from './AttachScreenshot.jsx';
import packageJson from '../../../package.json'; // Import the version from package.json

const GeneralFeedback = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.isGeneralFeedbackOpen);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to control animation and button state

  const placeholderTexts = [
    'It would be cool if you could....',
    'I got an error when...',
    'I like that you can...',
    "I don't like it when...",
  ];

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [screenshots, setScreenshots] = useState([]);

  useEffect(() => {
    if (!isFocused && !feedback) {
      const interval = setInterval(() => {
        setPlaceholderIndex(
          (prevIndex) => (prevIndex + 1) % placeholderTexts.length
        );
      }, 2000); // Change placeholder every 2 seconds
      return () => clearInterval(interval);
    }
  }, [isFocused, feedback]);

  useEffect(() => {
    if (!isOpen) {
      setFeedback(''); // Reset the feedback input when closed
      setScreenshots(null);
    }
  }, [isOpen]);

  const onSubmitFeedback = async (e, feedback, screenshots) => {
    e.preventDefault();
    setIsSubmitting(true); // Start the loading animation

    try {
      // Prepare form data
      const formData = new FormData();
      formData.append('feedback', feedback);  // Properly append feedback
  
      // Append version from package.json
      formData.append('version', packageJson.version);
  
      if (screenshots && screenshots.length > 0) {
        screenshots.forEach((file) => {
          formData.append('screenshot', file); // 'screenshot' matches the field name in multer
        });
      }
  
      // Submit feedback to the backend
      await axiosInstance.post('/api/general-feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Close the feedback modal after submission
      dispatch(setIsGeneralFeedbackOpen(false));
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false); // Stop the loading animation
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        open={isOpen}
        onClose={() => dispatch(setIsGeneralFeedbackOpen(false))}
        className="relative z-10"
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 max-h-[calc(100vh-50px)] overflow-y-auto">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => dispatch(setIsGeneralFeedbackOpen(false))}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex justify-center space-x-4 mt-4">
                  <HandThumbUpIcon className="h-8 w-8 text-gray-600" />
                  <HandThumbDownIcon className="h-8 w-8 text-gray-600" />
                </div>
                <div className="mt-3 text-center sm:mt-4">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Share Your Feedback
                  </DialogTitle>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      Your feedback is super important. We read it all. Thank
                      you for your time!
                    </p>
                  </div>
                </div>
                <form className="mt-6" onSubmit={(e) => onSubmitFeedback(e, feedback, screenshots)}>
                  <div className="space-y-6">
                    {/* Textarea */}
                    <div className="relative">
                      <textarea
                        id="feedback"
                        name="feedback"
                        rows={4}
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-transparent focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      />
                      {(!isFocused && !feedback) && (
                        <Transition
                          appear
                          show
                          enter="transition-opacity duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-500"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="absolute top-0 left-0 py-1.5 px-2 text-sm text-gray-400 pointer-events-none">
                            {placeholderTexts[placeholderIndex]}
                          </div>
                        </Transition>
                      )}
                    </div>

                    {/* AttachScreenshot Component */}
                    <AttachScreenshot
                      onScreenshotChange={(files) => setScreenshots(files)}
                    />

                    {/* Loading Animation */}
                    {isSubmitting && (
                      <div className="flex justify-center items-center mt-4">
                        <l-line-wobble
                          size="300"
                          stroke="5"
                          bg-opacity="0.1"
                          speed="1.75"
                          color="#8A79EC"
                        ></l-line-wobble>
                      </div>
                    )}
                  </div>

                  {/* Submit and Cancel Buttons */}
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => dispatch(setIsGeneralFeedbackOpen(false))}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                        isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500'
                      }`}
                      disabled={isSubmitting} // Disable button while submitting
                    >
                      Send
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GeneralFeedback;