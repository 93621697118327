import React, { useState } from 'react';
import * as HeroIcons from '@heroicons/react/24/solid'; // Import all solid icons from the latest version

const PickHeroIcon = ({ onSelectIcon, onClose, object }) => {
  // State to track the search term
  const [searchTerm, setSearchTerm] = useState('');

  // Filter icons based on the search term
  const filteredIcons = Object.keys(HeroIcons).filter((iconName) =>
    iconName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle selecting an icon and resetting the search term
  const handleSelectIcon = (iconName) => {
    onSelectIcon(iconName);
    onClose();
    setSearchTerm(''); // Reset the search bar after selecting an icon
  };

  return (
    <div>
      {/* Display a dynamic heading with the object's label and search input */}
      <div className="flex items-center justify-between mt-5">
        <h1 className="text-sm py-3 pt-3 ml-2 ">
          Choose an icon for <strong className="text-indigo-500">{object?.label}</strong>:
        </h1>
        {/* Search bar */}
        <input
          type="text"
          placeholder="Search icons..."
          className="border border-gray-300 rounded-md p-1 mr-2 text-sm w-48"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        />
      </div>
      <div className="rounded-lg border border-gray-300 bg-white p-4 overflow-y-auto h-36">
        <div className="grid grid-cols-6 gap-4">
          {filteredIcons.length > 0 ? (
            filteredIcons.map((iconName) => {
              const IconComponent = HeroIcons[iconName]; // Get the icon component by its name

              return (
                <button
                  key={iconName}
                  className="flex items-center justify-center p-2 rounded-lg border border-gray-200 hover:bg-gray-100"
                  onClick={() => handleSelectIcon(iconName)} // Select and reset search
                >
                  {/* Render the icon dynamically */}
                  <IconComponent className="h-4 w-4 text-gray-500" aria-hidden="true" />
                </button>
              );
            })
          ) : (
            <p className="text-sm text-gray-500 col-span-6">No icons found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickHeroIcon;